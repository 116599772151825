export default [
  // SWORD AND SHIELDS
  { value: "expantion", label: "SWORD AND SHIELDS" },
  { value: "SS 01", label: "Baraja temática Charizard", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SwordAndShield/charizard.png' },
  { value: "SS 02", label: "Baraja temática Drednaw", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SwordAndShield/drednaw.png' },
  { value: "SS 03", label: "Baraja temática Darmanitan", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SwordAndShield/Damanitan.png' },
  { value: "SS 04", label: "Baraja temática Sirfetch'd", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SwordAndShield/Sirfecth.png' },
  { value: "SS 05", label: "Baraja temática Zacian", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SwordAndShield/Zacian.png' },
  { value: "SS 06", label: "Baraja temática Zamazenta", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SwordAndShield/Zamazenta.png' },
  { value: "SS 07", label: "Baraja temática Rillaboom", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SwordAndShield/Rillaboom.png' },
  { value: "SS 08", label: "Baraja temática Cinderace", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SwordAndShield/Cinderace.png' },
  { value: "SS 09", label: "Baraja temática Inteleon", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SwordAndShield/Inteleon.png' },
  // SUN AND MOONS
  { value: "expantion2", label: "SUN AND MOON" },
  { value: "SM 16", label: "Volcán en Llamas", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Volcán en llamas.png' },
  { value: "SM 17", label: "Invocador de Tormentas", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Invocador de tormentas.png' },
  { value: "SM 17_1", label: "Let's Play, Pikachu!", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Lets Play Pikachu.png' },
  { value: "SM 17_2", label: "Let's Play, Eevee!", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Lets Play Eevee.png' },
  { value: "SM 18", label: "Llama Implacable", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Llama Implacable.png' },
  { value: "SM 19", label: "Cañón Torrencial", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Cañón Torrencial.png' },
  { value: "SM 20", label: "Circuito Fulminante", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Circuito fulminante.png' },
  { value: "SM 21", label: "Combate Mental", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/combate mental.png' },
  { value: "SM 22", label: "Tormenta Creciente", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Tormenta creciente.png' },
  { value: "SM 23", label: "Concentración Láser", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Concentración Laser.png' },
  { value: "SM 24", label: "Cumbres Sublimes", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Cumbres sublimes.png' },
  { value: "SM 25", label: "Abismos Ocultos", urlImage: 'gs://thenest-panini.appspot.com/DecksTematicos/SunAndMoon/Abismos ocultos.png' },
]