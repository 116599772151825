import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import imgTicket from '../assets/images/ticket.png'

const useStyles = makeStyles((theme)=>({
    imgTicket: {

    },
  }));

const DialogTicket = (props) => {

    const classes = useStyles();
    const { onClose, open } = props;

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} onClose = {onClose}>
        <DialogTitle id="simple-dialog-title">Tickets de compra</DialogTitle>
        <img src={imgTicket} className={classes.imgTicket}/>
      
    </Dialog>
    )
}

export default DialogTicket
