import React,{useState,useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { writeRespuestas} from '../Redux/preguntasDucks';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1, 1, 0, 0),
    },
  }));


const Preguntas = (props) => {  
    //const respuestas = respuestasArray;

    const classes = useStyles();
    const [value, setValue] = React.useState('');
   // const [formValues, setFormValues] = React.useState(initialFormValues);
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('Elige una opción');

    const dispatch = useDispatch();
    const preguntas = useSelector(store=> store.preguntas);
   
/*
    useEffect(() => {
      console.log("efecto de cambio en el form Values" + JSON.stringify(preguntas))
      return () => {
        console.log("me limpio el efecto esto podria limpuar cyando cambio de pantalla")
      }
    }, [preguntas])
    /*
    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setHelperText(' ');
        setError(false);
      };*/

      const handleRadioChange = e => {

        
        const { name, value } = e.target;

        console.log(" Antes formValues" + JSON.stringify(preguntas))
        console.log("respuestas " + JSON.stringify(preguntas.respuestas))
        /*
        setFormValues({
          ...formValues,
          [name]: value
        })
        console.log("Desúes  formValues" + JSON.stringify(formValues))*/

        dispatch(writeRespuestas(name,value))
      }

    return (
        <div>
            
            <Typography variant="h6" align="left">
                {props.pregunta}
            </Typography>
            <FormControl component="fieldset" error={preguntas.tempError[props.nombre] !== "" ? true:false}>
                <RadioGroup  id={props.nombre} row aria-label={props.nombre} name={props.nombre} onChange={handleRadioChange}>
                    {props.respuestas.map((respuesta)=>(
                      
                    <FormControlLabel value={respuesta} control={<Radio /> } label={respuesta}/>
                ))}
                </RadioGroup>
                {preguntas.tempError[props.nombre] !== "" ?
                <FormHelperText>{preguntas.tempError[props.nombre]}</FormHelperText>:<></>
                }
                <br/>
            </FormControl>

        </div>
    )
}

export default Preguntas
