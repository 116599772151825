import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import NavLink from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {cerrarSesionAccion} from '../Redux/usuarioDucks';
import {useDispatch,useSelector} from 'react-redux';

const ListaMenu = () => {

    const dispatch = useDispatch();
    const jugador = useSelector(store => store.usuario)
    const cerrarSesion = () => {
        dispatch(cerrarSesionAccion()).then(
         //props.history.push('login')
        );
       //
     }

    return (
        <div>
            <List component='nav'>
            <NavLink component={RouterLink} color="secondary" to="/" >
                <ListItem button>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Torneo" />
                </ListItem>
                </NavLink>

                <NavLink component={RouterLink} color="secondary" to="/#seccionRegistro" >
                    <ListItem button>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Registro" />
                    </ListItem>
                </NavLink>
                <NavLink component={RouterLink} color="secondary" to="/reglas" >
                    <ListItem button>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Reglas" />
                    </ListItem>
                </NavLink>
                <ListItem button>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="FAQ" />
                </ListItem>
                
                <Divider />
               
                {jugador.activo === true ?
                
                    <ListItem button onClick={()=> cerrarSesion()}>
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                            <ListItemText>
                                SALIR
                            </ListItemText>
                     
                        </ListItem>:  
                  <NavLink component={RouterLink} color="secondary" to="/login" >
                  <ListItem button>
                      <ListItemIcon>
                          <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Ingresar" />
                  </ListItem>
              </NavLink>
                        }
            </List>
        </div>
    )
}

export default ListaMenu
