import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { registrarDeckStandard,registrarDeckTematico } from '../Redux/playerTournamentData';
import Alert from '@material-ui/lab/Alert';
import Progress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import DeckLists from '../dataLists/deckLists';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { storage } from '../firebase';
import _ from 'lodash';
import RegDeckStandard from './RegDeckStandard';
import RegDeckTematico from './RegistroDeckTematico';

const useStyles = makeStyles((theme) => ({
   boton: {
      marginTop:theme.spacing(0)
   },
   InputDeckBox: {
      border: 1,
      borderColor: theme.palette.primary.main,
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(4),

      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
         paddingLeft: theme.spacing(0),
         paddingRight: theme.spacing(0),

      }

   }, CajaDeckRegistrado: {
      border: 6,
      borderColor: theme.palette.secondary.main,
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
         paddingTop: theme.spacing(0),
      },


   }, instrucciones: {
      paddingBottom: theme.spacing(2),
   },
   loadingBar: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)

   }, imageDeckList: {
      alignItems: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'block',
      height: 250,
      width: 'auto'
   }, form: {
      width: '100%', // Fix IE 11 issue.
      display: "flex"
   },
   containerItemImage: {
      [theme.breakpoints.down('sm')]: {
         paddingTop: theme.spacing(3),
      },
   },
   TextFieldsTematico:{
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
         paddingBottom: theme.spacing(3),
         paddingRight: theme.spacing(3),
         paddingLeft: theme.spacing(3),
      },
   }
}));

const placeHolderDeck = `****** Pokémon Trading Card Game Deck List ******

    ##Pokémon - 19
    
    * 1 Dubwool V RCL 153
    * 2 Type: Null UNM 183
    * 2 Silvally-GX CEC 184
    * 1 Crobat V DAA 104
    * 3 Centiskorch V DAA 33
    * 1 Heatran-GX UNM 25
    * 3 Volcanion UNB 25
    * 3 Centiskorch VMAX DAA 342
    * 3 Giant Hearth UNM 197
    * 1 Eldegoss V RCL 19
    * 2 Dedenne-GX UNB 195
    
    ##Trainer Cards - 29
    
    * 2 Cherish Ball UNM 191
    * 4 Quick Ball SSH 179
    * 3 Pokémon Communication TEU 15
    * 1 Mallow & Lana CEC 198
    * 4 Switch SUM 132
    * 4 Welder UNB 189
    * 2 Reset Stamp UNM 206
    * 1 Bird Keeper DAA 159
    * 1 Air Balloon SSH 156
    * 2 Fire Crystal UNB 173
    * 2 Boss's Orders RCL 154
    
    ##Energy - 12
    
    * 1 Heat {R} Energy DAA 174
    * 11 Fire Energy Energy 2
    
    Total Cards - 60
    
    ****** Deck List Generated by the Pokémon TCG Online www.pokemon.com/TCGO ******`;


const initialFormValues = {
   ptcgoName: '',
   deckList: '',
}

const RegistroDecks = (props) => {

   
   //VAriables del componente de Redux Estandard
   const dataPlayer = useSelector(store => store.playerTournamentData.dataPlayer);
  
   return (<>{

      (dataPlayer.userData && (dataPlayer.userData.torneo === "jugadoresStandard") ? <>
         <RegDeckStandard/>
      </> : <>
      <RegDeckTematico/>
      </>

      )

   }</>)
}

export default RegistroDecks
