import { auth,db } from "../firebase"

// data inicial
const dataInicial = {
    loading : false,
    error:"",
    activo:false,
    
}

//types
const LOADING = 'LOADING'
const USUARIO_ERROR = 'USUARIO_ERROR'
const USUARIO_EXITO = 'USUARIO_EXITO'
const USUARIO_LOGOUT = "USUARIO_LOGOUT"

//REDUCER
export default function usuarioReducer (state = dataInicial, action){

    switch(action.type){
        case LOADING:
            return {...state, loading: true}
        case USUARIO_ERROR:
            return {...dataInicial,error:action.payload}
        case USUARIO_EXITO:
                return {...state,loading:false, user: action.payload,activo:true}
        case USUARIO_LOGOUT:
                return { ...dataInicial}
        default:
            return{...state}
        }

}

//ACTIONS

// action lleva async porque hara consulta a una db puede o no llevar getState
export const ingresoUsuarioAccion = (formData) => async(dispatch) =>{
    //dispatch independiente si se registra el usuario o no debe actualizarse el loading util para spinners o mensajes de conectgando a la db
    dispatch({
        type:LOADING
    })

    try{
        //Login a API de Firebase
        const res = await auth.signInWithEmailAndPassword(formData.email,formData.password);
        //Vamos a leer la data del user
        const usuarioDB = await db.collection('usuarios').doc(res.user.email).get();

        dispatch({
            type: USUARIO_EXITO,
            payload: {
                uid: res.user.uid,
                email: res.user.email,
                estado: usuarioDB.data().estado,
                fechaNacimiento: usuarioDB.data().fechaNacimiento,
                firstName: usuarioDB.data().firstName,
                lastName: usuarioDB.data().lastName,
                torneo: usuarioDB.data().torneo,
                telefonoTutor:usuarioDB.data().telefonoTutor,
                tutor: usuarioDB.data().tutor
            }
        })
    }catch(error){
        console.log(error)
        //console.log(error);
        let errorX = ""

        if(error.code === 'auth/wrong-password'){
            errorX = "Password Incorrecto"
        }else if (error.code === 'auth/user-not-found'){
           errorX = "Usuario no encontrado." 
        }else if (error.code === 'auth/too-many-requests'){
            errorX = "El acceso a esta cuenta ha sido temporalmente desabilitado debido por muchos intentos de acceso. Puedes restaurarlo inmediatamente reseteando tu password o intentarlo mas tarde." 
        }else{
            errorX = error.message
        }
        dispatch({
            type : USUARIO_ERROR,
            payload: errorX
        })
    }

}

export const cerrarSesionAccion = ()=> async(dispatch) =>{
    auth.signOut();
    localStorage.removeItem('usuario');
    dispatch({
        type: USUARIO_LOGOUT
    })
}