import {createTheme} from '@material-ui/core/styles'

const tema = createTheme({
    typography: {
        fontFamily: 'sans-serif',
      },
    palette:{
        primary:{
            //main:'#A41919',
            main:'#000000',
            dark:'#6e0000',
            light:'#dc5041' 
            },     
           secondary:{
            main:'#E00C19',
            dark:'#ad6400',
            light:'#ffc248'
            },
           info:{
               main:'#3f51b5'
           },
           success:{
            main:'#368F0A' 
           },
           warning:{
            main:'#ffc446'
           },
           text:{
            primary: '#000000',
            secondary: '#e0dfe0',
            disabled: '#3d3d3d',
           },
    }
})

export default tema