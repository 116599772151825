import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Preguntas from './Preguntas';
import cuestionario from '../dataLists/cuestionario';
import TicketForm from './TicketForm';
import Progress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { enviarRespuestas, erroresDeSeleccion } from '../Redux/preguntasDucks';
import { operarStepper, syncroDataTorneo } from '../Redux/playerTournamentData';
import RegistroDecks from './RegistroDecks';
import PreguntasYRes from './PreguntasYRes';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    flexGrow: 1,

  }, subRoot: {

    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),

    }

  },
  paper: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
  
      }

  }, backButton: {
    marginTop: theme.spacing(3),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  botonesStepper:{
    flexGrow:1,
    display:"flex",
    alignSelf:"right",
    paddingTop:theme.spacing(2)
  },divider:{
    marginTop:theme.spacing(2)
  }

}));

function getSteps() {
  return ['Cuestionario', 'Registro de Ticket', 'Registro de deck y Username'];
}



function getStepContent(step, playerData) {

  console.log("playerDataenStepContent " + JSON.stringify(playerData.dataPlayer))

  switch (step) {
    case 0:
      return <PreguntasYRes isDisabled ={ step <= (playerData.dataPlayer.paso - 1)   ? false:true} />;
    case 1:
      return <TicketForm  isDisabled ={ step <= (playerData.dataPlayer.paso - 1)   ? false:true} dataPlayer={playerData.dataPlayer}/>;
    case 2:
      return <RegistroDecks playerData={playerData} />;
    default:
      return 'Loading Data';
  }
}

const StepperRegistro = () => {

  const classes = useStyles();

  //Aqui intentare el tiempo real activeStep
  const dispatch = useDispatch();

  const playerData = useSelector(store => store.playerTournamentData);
  const loadingData = useSelector(store => store.playerTournamentData.loadingData);

  useEffect(() => {
    //Sincronizamos la data y dejamos pegada la data del torneo
    console.log("me dibujare stepperRegistro")
    dispatch(syncroDataTorneo())
    console.log("ya no me dibuje")
    return () => {
      console.log("me desuscribire")
      //dispatch(desuscribirLaSyncro())
    }
  }, [])

  useEffect(() => {
    setActiveStep(playerData.dataPlayer.paso)
  }, [loadingData])

  //const [activeStep, setActiveStep] = React.useState(dataPlayer.paso);
  //const dataPlayer = useSelector(store=> store.playerTournamentData.dataPlayer)

  const [activeStep, setActiveStep] = React.useState(playerData.dataPlayer.paso);



  const steps = getSteps();

  const handleNext = () => {

    //dispatch(operarStepper("suma"));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    //dispatch(operarStepper("restar"))
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    //dispatch(operarStepper("reset"))
    setActiveStep(0);
  };

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.subRoot}>

              <Stepper activeStep={playerData.dataPlayer.paso} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}

              </Stepper>
              <Divider />


              <br />
              {activeStep === steps.length ? (
                <div>{ playerData.dataPlayer.codigoBattlefy === undefined  ?
                  <Typography className={classes.instructions}>Espera la validación del ticket para obtener tu codigo al torneo</Typography> :<>
                  {<>
                     <Typography className={classes.instructions}>Codigo de torneo a Battlefy: <strong>{playerData.dataPlayer.codigoBattlefy}</strong></Typography>
               
                     {playerData.dataPlayer.userData.torneo ==="jugadoresStandard" ? 
                  <Typography className={classes.instructions}>Link al torneo: <a href="https://battlefy.com/bravebirds-the-nest/panini-cup-standard-league/610d6562499b9f2858ce7e09/info" target="_blank"> Panini Cup Standard League</a> </Typography>
                  : <Typography className={classes.instructions}>Link al torneo: <a href="https://battlefy.com/bravebirds-the-nest/panini-cup-theme-league/610d6c09b21a281177fd9062/info" target="_blank"> Panini Cup Theme League</a></Typography>
                }</>
                }</>
                }
                <Grid container justifyContent="flex-end" >
                <Button variant="outlined" onClick={handleBack}>Volver y cambiar Deck</Button></Grid>
                </div>
              ) : (
                <div>
                  {playerData.dataPlayer.loadingData ? <></> : getStepContent(activeStep, playerData)}
                  {<>
                  <Divider className={classes.divider}/>
                  <Grid container justifyContent="flex-end" >
                    <Grid item  >
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}
                      >
                        Atras
                      </Button>
                      <Button variant="outlined" disabled={  activeStep <= (playerData.dataPlayer.paso - 1)   ? false:true} color="primary" onClick={handleNext} className={classes.backButton}>
                        {activeStep === steps.length - 1 ? 'Siguiente' : 'Siguiente'}
                      </Button>
                    </Grid>
                    </Grid>
                    </>
                  }
                </div>
              )}

            </div></Grid></Grid>
      </Paper>
    </Container>
  );
}

export default StepperRegistro
