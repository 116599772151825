import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LinkMaterialUI from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Progress from '@material-ui/core/LinearProgress';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registrarUsuarioAccion, resetForm } from '../Redux/registroThemeDucks';
import { withRouter } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import EstadosList from '../dataLists/estados';
import LogoTheNest from '../assets/images/StandardLogo.svg';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <LinkMaterialUI color="inherit" href="https://material-ui.com/">
        Brave Birds S.A. de C.V.
      </LinkMaterialUI>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  password2: '',
  estado: '',
  fecha: '',
  telefonoTutor: '',
  tutor: '',
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontSize: '1.2rem',
    paddingLeft: '50px',
    paddingRight: '50px',
  }, subtitulo: {
    opacity: 1
  },
  logotheNest: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    height: 280,
    width: 'auto'
  },
  terminos: {
    display: "flex",

  },
  checkBoxTerminos: {
    marginRight: theme.spacing(1)
  }
}));

function RegistroStandard(props) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const loadingRegister = useSelector(store => store.registroUser.loading);
  const errorRegister = useSelector(store => store.registroUser.error);
  const registroSuccess = useSelector(store => store.registroUser.success);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState({});
  const [terminosOk, setTerminoOk] = useState(true);
  const [isMenorDeEdad, setIsMenorDeEdad] = useState(false);
  const listaEstados = EstadosList;

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  //Funcion para actualizar en React la input data
  //se debe usar name y value porque son vienen de target y destructuring de name y value
  const handleInputChange = e => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value
    })
    //if(validateOnChange) https://www.youtube.com/watch?v=4YLa9iuN43c&t=693s
  }

  function validateForm() {

    let tempError = {}
    tempError.firstName = formValues.firstName ? "" : "Este campo es obligatorio."
    tempError.lastName = formValues.lastName ? "" : "Este campo es obligatorio."
    tempError.email = (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(formValues.email) ? "" : "El correo no es valido."
    tempError.password = (formValues.password.length) > 7 ? "" : "Introduce almenos 8 digitos."
    tempError.password2 = (formValues.password === formValues.password2) ? "" : "Las contraseñas no coinciden"
    tempError.estado = formValues.estado ? "" : "Este campo es obligatorio."
    tempError.fecha = formValues.fecha ? "" : "Ingresa tu fecha de nacimiento"
   //Campos si son menores de edad para validar
   if(isMenorDeEdad === true ){
    tempError.tutor = formValues.tutor ? "" : "Ingresa el nombre de tu padre o tutor"
    tempError.telefonoTutor = formValues.telefonoTutor ? "" : "Telefono requerido"
   }else{
     tempError.tutor ="";
     tempError.telefonoTutor ="";
   }
    
    console.log(tempError.password)

    setFormErrors({
      ...tempError
    })

    //hace una comparativa en el objeto temporal para validar las validaciones del formulario
    return Object.values(tempError).every(x => x === "")
  }

  const handleSubmit = e => {

    e.preventDefault();

    if (validateForm()) {
      //setFormValues(initialFormValues);
      console.log("formulario valido con estos valores")
      console.log("formValues" + JSON.stringify(formValues))
      dispatch(registrarUsuarioAccion(formValues,"jugadoresStandard"))
      setFormValues(initialFormValues);
      return
    }
    console.log("formulario invalido")
    console.log("formValues" + JSON.stringify(formValues))
  }

  //Funcion para cambiar el checkbox
  const checkboxTerminos = () => {
    setTerminoOk(!terminosOk);
  }

  //Funcion para calcular edad
  const calcuMayor18 = (e) => {
     
    const { value } = e.target;
    let todayDate = new Date();
    let cumple = new Date(value);
    let edad = todayDate.getFullYear() - cumple.getFullYear();
    let m = todayDate.getMonth() - cumple.getMonth();
    if (m < 0 || (m === 0 && todayDate.getDate() < cumple.getDate())) {
      edad--;
    }
    if (edad >= 18) {
      //console.log("Mayor de edad")
      setIsMenorDeEdad(false);
    } else {
      //console.log("Menor de edad")
      setIsMenorDeEdad(true);
    }
  }

  useEffect(()=>{
    //console.log("elefecto de is menor de edad")
  if(isMenorDeEdad ==false){
   // console.log("seteo dentro del efecto")
  setFormValues({
    ...formValues,
     telefonoTutor: '',
     tutor: ''});}

},[isMenorDeEdad])

  useEffect(() => {
    if (registroSuccess) {
      /*setTimeout(function () {
        dispatch(resetForm());
      }, 4000);*/
      dispatch(resetForm());
      props.history.push("login")
    }
  }, [registroSuccess])

  useEffect(() => {
    if (errorRegister) {
      setTimeout(function () {
        dispatch(resetForm());
      }, 6000);
    }
  }, [errorRegister])


  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={LogoTheNest} className={classes.logotheNest} alt="logoTheNest" />
        <form className={classes.form} noValidate autoComplete="true" onSubmit={handleSubmit}>
           {/** 
          <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Nombres"
                autoFocus
                error={formErrors.firstName}
                helperText={formErrors.firstName}
                value={formValues.firstName}
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Apellidos"
                name="lastName"
                autoComplete="lname"
                error={formErrors.lastName}
                helperText={formErrors.lastName}
                value={formValues.lastName}
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                select
                fullWidth
                size="small"
                id="country"
                label="Estado"
                name="estado"
                error={formErrors.estado}
                helperText={formErrors.estado}
                value={formValues.estado}
                onChange={handleInputChange}>
                {listaEstados.map((option) => (
                  option.value == "continent" ? <MenuItem disabled className={classes.subtitulo}>
                    <Box className={classes.subtitulo}>
                      {option.label}
                    </Box>
                  </MenuItem> :
                    <MenuItem key={option.value} value={option.value}>
                      { }{"    "}{option.label}
                    </MenuItem>
                ))}
                ))
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="fecha"
                variant="outlined"
                required
                fullWidth
                size="small"
                id="fechaNacimiento"
                label="Fecha de Nacimiento"
                inputProps={{ type: 'date', min:"1970-08-28", max:"2014-08-28" }}
                value={formValues.fecha}
                error={formErrors.fecha}
                helperText={formErrors.fecha}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => { calcuMayor18(e); handleInputChange(e);  }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                error={formErrors.email}
                helperText={formErrors.email}
                value={formValues.email}
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                {...(formErrors.password && { error: true, helperText: formErrors.password })}
                value={formValues.password}
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password2"
                autoComplete="new-password"
                label="Confirm Password"
                type="password"
                id="password2"
                error={formErrors.password2}
                helperText={formErrors.password2}
                value={formValues.password2}
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
           
            {isMenorDeEdad ? 
              <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12} sm={12}>
                <br />
                <Divider />
                  <Typography variant='subtitle1' align="center"><p />Ingresa los datos de tu padre o tutor</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="tutor"
                    variant="outlined"
                    required
                    fullWidth
                    id="tutor"
                    label="Nombre de Padre o tutor"
                    error={formErrors.tutor}
                    helperText={formErrors.tutor}
                    value={formValues.tutor}
                    onChange={handleInputChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField 
                    variant="outlined"
                    required
                    fullWidth
                    id="telefono"
                    label="Telefono de padre o tutor"
                    name="telefonoTutor"
                    inputProps={{ type: 'tel', pattern: '[0-9]{10}' }}
                    error={formErrors.telefonoTutor}
                    helperText={formErrors.telefonoTutor}
                    value={formValues.telefonoTutor}
                    onChange={handleInputChange}
                    size="small"
                    {...props}
                  />
                </Grid>
              </Grid>
               : <></>
            }
        
       
            <Grid item xs={12} align="center" justify="center" alignItems="center" className={classes.terminos}>
              <FormControlLabel
                errorText="!sad"
                value={terminosOk}
                onChange={checkboxTerminos}

                name="terminos"
                control={
                  <Checkbox

                    color="primary"
                  />

                }
                label="Acepto" className={classes.checkBoxTerminos}
              /><Link to="/terminosYCondiciones" >
                <Typography variant='subtitle1'>terminos y condiciones</Typography>
              </Link>
            </Grid>
          </Grid>


          {errorRegister && (<Box mt="20px"><Alert severity="error">{errorRegister}</Alert></Box>)}
          {loadingRegister && (<Progress />)}
          {registroSuccess && (<Box mt="20px" textAlign="center"><Typography variant="subtitle1" align="center" color="success" /><Alert autoHideDuration={3000} severity="success">
            Register Success <Link to="login" variant="body2">{"    Login Here"}</Link>
          </Alert></Box>)}
          <Grid container item justify="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
              disabled={loadingRegister}
              disabled={terminosOk}
            >
              Registrarme
            </Button>
          </Grid>*/}
          <Typography paragraph variant="h5" color="primary" align="center">El registro ha cerrado</Typography>
          <Typography paragraph variant="h6" color="primary" align="center">Te esperamos en el torneo, gracias por participar</Typography>
          <Grid container justify="center">
            <Grid item>
              <Link to="login" variant="body2">
                ¿Ya tienes una cuenta? Ingresa aquí
              </Link>
            </Grid>
          </Grid>

        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
export default withRouter(RegistroStandard)