import {ThemeProvider}  from "@material-ui/core/styles";
import CssBaseline  from "@material-ui/core/CssBaseline";
import MainContenedor from "./componentes/MainContenedor";
import tema from './styles/temaConfig'
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import generateStore from './Redux/store';
import {Provider} from 'react-redux';

function App() {

  //Generamos la store de Redux
  const store = generateStore();

  return (
    <Provider store = {store} > 
      <Router>
          <ThemeProvider theme={tema}>
            <CssBaseline/>
              <Switch>
                <MainContenedor/>
              </Switch>
          </ThemeProvider>
      </Router>
    </Provider> 
  );
}

export default App;
