import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography';
import ScrollIndicator from '../componentes/ScrollIndicator';
import { useLocation } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import MainBackground from './../assets/images/Banner.png';
import Container from '@material-ui/core/Container';

//Jalamos estilos del tema y customizamos
const useStyles = makeStyles(theme => ({

    paper: {
        //background: '#9319FF',
        textAlign: 'center',
    }, paperRegistros: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingRight: theme.spacing(0),
            paddingLeft: theme.spacing(0),
            //backgroundColor:'#002632'
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            //backgroundColor:'#124452'
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(7),
            paddingBottom: theme.spacing(8),
            paddingRight: theme.spacing(8),
            paddingLeft: theme.spacing(8),
            //backgroundColor:'#221642'
        }
    },
    botonPortada: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    botonRegistro: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    logoRegistro: {
        display: 'block',
        width: '50%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(0)
    },
    seccionPortada: {
        backgroundImage: `url(${MainBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: 'auto',
        height: '100vh',
        display: 'flex',
    }, elementsPortada: {
        paddingBottom: theme.spacing(16),
    },
    seccionTorneo: {
        background: '#FFFFFF',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),

    }, seccionRegistro: {
        backgroundColor: '#F2F2F2',
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(6)

    }, seccionPreguntas2: {
        backgroundColor: '#FFFFFF',
    },
    /** imagenes **/
    imgTorneo: {
        width: '100%',
        padding: theme.spacing(4)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const PreguntasFrecuentes = () => {
    const classes = useStyles();

    return (
        <Container   className={classes.seccionPreguntas2}>
        <Grid container direction="column" justifyContent='flex-start' alignItems="center">
            <Grid item>
                <Typography variant="h4" gutterBottom align='center'>Preguntas Frecuentes<br/><br/></Typography>
            </Grid>
            <Grid item>
                <Typography variant="h6">Antes del torneo<br /></Typography>
            </Grid>
            <br />
            <Grid item>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>1.- ¿Cómo encuentro mi Panini Point más cercano?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Muy sencillo, a través de la página <a href=" https://www.puntospanini.com/" target="_blank"> https://www.puntospanini.com/ </a> podrás encontrar tu tienda más cercana
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2.- ¿Qué es Battlefy?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Battlefy es una plataforma para realizar torneos. Es intuitiva y permite que los torneos se realicen de manera fluida. Esta es la dirección <a href=" https://www.battlefy.com/" target="_blank"> https://www.battlefy.com/ </a>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>3.-¿Dónde encontrar el torneo de PANINI en Battlefy?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Una vez que te hayas registrado y verifiquemos tu compra, recibirás una invitación directa al torneo de la categoría correspondiente a través de la plataforma The Nest.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>4.-¿Cómo registrarme en Battlefy?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lo único que necesitas es un correo electrónico y una contraseña. Battlefy también permite conectarse a través de Google y Facebook.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>5.-¿Cómo elijo mi categoría?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Durante el registro podrás elegir la categoría en la que quieres competir, piensa muy bien en cual quieres participar ya que solo podras elegir una y no la podras cambiar. </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>6.-¿Qué pasa si no me llega el correo de confirmación?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Ponte en contacto con nuestro staff a través del discord de Brave Birds</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>7.-¿Qué es Discord?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Discord es una plataforma de comunicación que permite tener chat, canales de voz e incluso videollamadas.  </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>8.-¿Dónde encuentro el Discord de Brave Birds?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Simplemente haz click en el siguiente link: <a href="https://discord.gg/BDaZte4SFu" target="_blank">  https://discord.gg/BDaZte4SFu</a>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <br /><br />
            <Grid item>
                <Typography variant="h6">Durante el torneo<br /></Typography>
            </Grid>
            <br />
            <Grid item>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>1.- ¿Cómo envío una solicitud de amistad a mi oponente?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        En la esquina superior derecha de Pokémon juego de cartas coleccionables online encontrarás la pestaña de amigos. Haz click en el botón de Enviar nueva solicitud de amistad y escribe el nombre de tu contrincante. 
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2.-¿Cómo reportar que gané/perdí una partida?

                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        El reporte se hace directamente en Battlefy, en la pantalla de Match puedes encontrar un botón de Reportar Resultados y selecciona a los ganadores de cada juego.
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>3.- ¿Qué hago si mi oponente no ha llegado?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Si tu oponente no hace Check-in en los primeros 3 minutos, Battlefy te asignará la victoria. Si tu oponente sí hizo check in, pero después de 5 minutos no responde tu solicitud de amigo, toma una captura de pantalla con la hora y llama a un juez a la mesa. Para más información consulta el reglamento.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>4.-¿Qué pasa si me desconecto?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Si te desconectas, vuelve a entrar lo más rápido posible a Pokémon Juego de cartas coleccionables online. Si el juego te reconecta a la partida, podrás continuar sin problemas. Si el juego te envía al menú principal, tendrás el juego perdido. Para más información consulta el reglamento.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>5.-¿Qué hago si mi oponente está spameando emojis o frases del juego?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Si sientes que tu oponente no está siendo respetuoso durante su encuentro, toma captura de pantalla con la hora como evidencia de lo ocurrido y llama a un juez a la mesa. El juez determinará si se atribuye una sanción. Panini tiene una política de cero tolerancia en contra de la discriminación y acoso. Si existe algún caso de acoso, abuso, insulto, racísmo o discriminación por parte de algún jugador o algún otro participante del mismo en el evento, esta persona QUEDARÁ INMEDIATAMENTE DESCALIFICADA del evento.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <br /><br />
            <Grid item>
                <Typography variant="h6">Después del torneo<br /></Typography>
            </Grid>
            <br /><br />
            <Grid item>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>1.- ¿Dónde puedo recoger mi premio?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            En la Tienda Panini donde se adquirió el producto para inscribirse en el torneo, en caso de haber comprado en Tienda Panini en línea, se te asignará una tienda de acuerdo a tu lugar de residencia.
                         </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2.- ¿Qué pasa si no puedo recoger mi premio?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Tendrás 30 días para presentarte en la tienda o el premio se dará por perdido
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>3.-¿Puede ir alguien a recoger mi premio?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            No, el ganador tiene que presentarse en la tienda a recoger el premio
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className={classes.heading}>4.-¿Tienes alguna otra pregunta?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        ¡Únete a nuestro servidor de Brave Birds en Discord! ¡Nosotros te ayudaremos! 
                        Solo sigue el siguiente enlace: <a href="https://discord.gg/BDaZte4SFu" target="_blank">  https://discord.gg/BDaZte4SFu</a></Typography>
                    </AccordionDetails>
                </Accordion>
                
            </Grid>
        </Grid>
       </Container>
    )
}

export default PreguntasFrecuentes
