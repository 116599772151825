import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import usuarioReducer, { leerUsuarioActivoAccion } from './usuarioDucks';
import registroUserReducer from './registroThemeDucks';
import preguntasReducer from './preguntasDucks';
import playerTournamentDataReducer from './playerTournamentData';

const rootReducer = combineReducers({
    usuario: usuarioReducer,
    registroUser: registroUserReducer,
    playerTournamentData: playerTournamentDataReducer,
    preguntas: preguntasReducer


})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){
    const store = createStore(rootReducer,composeEnhancers(applyMiddleware(thunk)))
    //una vez que se crea la tienda hay que revisar si el usuario esta logeado con el local storage
    //leerUsuarioActivoAccion()(store.dispatch)
    return store;
}