export default [
    {
        "undefined": "0",
            value: "Online",
            label: "Tienda Panini Online",
            "Dirección": "https://www.tiendapanini.com.mx/",
            "Ciudad o Municipio": "Internet"
    },
        {
            "undefined": "1",
            value: "Pachuca",
            label: "Tienda Panini Pachuca",
            "Dirección": "Av. Revolución #1106 Col. Periodistas local 103 042060 Pachuca de Soto, México",
            "Ciudad o Municipio": "Pachuca"
        },
        {
            "undefined": "2",
            value: "San Rafael",
            label: "Tienda Panini San Rafael",
            "Dirección": "Manuel Maria Contreras 121, Local B7 06470 Ciudad de México, México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "3",
            value: "Plazas Outlet",
            label: "Tienda Panini Cancún | Plazas Outlet",
            "Dirección": "Tienda Panini Cancun Local 39 Plazas Outlet 77507 Cancún, México",
            "Ciudad o Municipio": "Cancún"
        },
        {
            "undefined": "4",
            value: "Friki Plaza Cancún",
            label: "Tienda Panini Cancún | Friki Plaza ",
            "Dirección": "Cancun Mall en la plaza de la tecnología local 302 y 303",
            "Ciudad o Municipio": "Cancún"
        },
        {
            "undefined": "5",
            value: "Plaza Lindavista",
            label: "Tienda Panini Lindavista",
            "Dirección": "Av. Montevideo 363, Lindavista Sur, Plaza Lindavista Primer Piso Local 233 07300 Ciudad de México, México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "6",
            value: "Plaza Coacalco",
            label: "Tienda Panini Coacalco",
            "Dirección": "Plaza Coacalco, Av José López Portillo 220, Parque Residencial Coacalco, San Francisco Coacalco, Méx. 55714 Estado de México",
            "Ciudad o Municipio": "EDOMEX"
        },
        {
            "undefined": "7",
            value: "Mexicali",
            label: "Tienda Panini Mexicali La Cachanilla",
            "Dirección": "Boulevard López Mateos 21100 Mexicali, Península de Baja California, México. Entrada 4 Local 11-C",
            "Ciudad o Municipio": "Mexicali"
        },
        {
            "undefined": "8",
            value: "Coyoacán",
            label: "Tienda Panini Coyoacán",
            "Dirección": "Dirección Av Miguel Hidalgo 9, Del Carmen, Coyoacán, 04100 Ciudad de México, CDMX",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "9",
            value: "Zona Rosa",
            label: "Tienda Panini Zona Rosa",
            "Dirección": "Niza # 66 - 101 Bis, Juárez Cuauhtémoc 06600 Ciudad de México, México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "10",
            value: "Portal Churubusco",
            label: "Tienda Panini Portal Churubusco",
            "Dirección": "Av. Rio Churubusco 583, 09060 ",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "11",
            value: "Culiacán",
            label: "Tienda Panini Culiacán",
            "Dirección": "Av. Gral. Juan Carrasco #340 80000 Culiacán",
            "Ciudad o Municipio": "Culiacán"
        },
        {
            "undefined": "12",
            value: "Toluca",
            label: "Tienda Panini Toluca",
            "Dirección": "Mariano Matamoros #109 Local 1-B 50000 Estado de México",
            "Ciudad o Municipio": "EDOMEX"
        },
        {
            "undefined": "13",
            value: "Plaza Las Américas",
            label: "Tienda Panini Metepec",
            "Dirección": "Plaza Las Américas, Calle Guadalupe Victoria 916, La Providencia, 52140 Metepec",
            "Ciudad o Municipio": "EDOMEX"
        },
        {
            "undefined": "14",
            value: "Puebla",
            label: "Tienda Panini Puebla",
            "Dirección": "Calle 4 Nte 401, Centro, 72000 Puebla, Pue. 72000 Puebla de Zaragoza, México",
            "Ciudad o Municipio": "Puebla"
        },
        {
            "undefined": "15",
            value: "Querétaro",
            label: "Tienda Panini Querétaro",
            "Dirección": "Avenida Corregidora 141 76000 Querétaro, México",
            "Ciudad o Municipio": "Querétaro"
        },
        {
            "undefined": "16",
            value: "Forum Buenavista",
            label: "Tienda Panini Forum Buenavista",
            "Dirección": "Eje 1 Norte 259 N2 Loc. 47, Buenavista Ciudad de México, México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "17",
            value: "Friki Plaza Uruguay",
            label: "Tienda Panini Friki Plaza Uruguay",
            "Dirección": "Frikiplaza Uruguay, Republica de Uruguay 17, Centro 06000 Ciudad de México, México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "18",
            value: "Friki Plaza Sótano",
            label: "Tienda Panini Friki Plaza Sótano",
            "Dirección": "Eje Central Lázaro Cárdenas #9 locales 12 y 14 en el sótano de la Frikiplaza Colonia Centro 06000 Ciudad de México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "19",
            value: "Ciudad Azteca",
            label: "Tienda Panini Cetram Cd. Azteca",
            "Dirección": "Av. Central, Mz. 632, Ext 50, Col. Cd. Azteca, Ecatepec de Morelos. 2do piso 55120 Estado de México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "20",
            value: "Plaza Cuauhtémoc",
            label: "Tienda Panini Plaza Cuauhtémoc",
            "Dirección": "Avenida Cuauhtemoc 19 Col. Roma LOCAL 37 06700 Ciudad de México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "21",
            value: "Plaza La Normal",
            label: "Panini Point Guadalajara Sucursal La Normal",
            "Dirección": "Avenida Avila Camacho 1275 local 16 centro comercial la normal 44630 Guadalajara (México)",
            "Ciudad o Municipio": "Guadalajara"
        },
        {
            "undefined": "22",
            value: "ETRAM Rosario",
            label: "Tienda Panini Cetram Metro Rosario",
            "Dirección": "AV. El Rosario 901, El Rosario 02100 Ciudad de México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "23",
            value: "ETRAM Toreo",
            label: "Tienda Panini Cetram Metro Toreo",
            "Dirección": "Av. Ingenieros Militares 70, Parque Industria 11230 Ciudad de México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "25",
            value: "El Parián",
            label: "Tienda Panini El Parian Aguascalientes",
            "Dirección": "centro comercial el parian local #25 planta alta 20000 Aguascalientes",
            "Ciudad o Municipio": "Aguascalientes"
        },
        {
            "undefined": "26",
            value: "Tecnocentro",
            label: "Tienda Panini Plaza Tecnocentro Guadalajara",
            "Dirección": "Av. Miguel Hidalgo y Costilla 469, Zona Centro 44100 Guadalajara (México)",
            "Ciudad o Municipio": "Guadalajara"
        },
        {
            "undefined": "27",
            value: "Tijuana",
            label: "Tienda Panini Tijuana Centro",
            "Dirección": ": Calle 3era Felipe Carrillo Puerto #7950, planta baja, Zona Centro, local 923, Tijuana, Baja California, C.P. 22000. Dentro de la Plaza de la Tecnología",
            "Ciudad o Municipio": "Tijuana"
        },
        {
            "undefined": "28",
            value: "Akiba Center",
            label: "Tienda Panini Monterrey Centro",
            "Dirección": "Avenida Múnich 105 San Nicolás de los Garza",
            "Ciudad o Municipio": "Monterrey"
        },
        {
            "undefined": "29",
            value: "Universidad",
            label: "Tienda Panini San Nicolás",
            "Dirección": "Guerrero 727 64000 Monterrey",
            "Ciudad o Municipio": "Monterrey"
        },
        {
            "undefined": "30",
            value: "Veracrúz",
            label: "Tienda Panini Veracruz",
            "Dirección": "Plaza del Dorado, Local J-8, Carretera Federal Paso del Toro, Boca del Rio, Anton Lizardo #4405, col san jose novillero, Boca del Rio Veracruz, CP 94290",
            "Ciudad o Municipio": "Veracrúz"
        },
        {
            "undefined": "31",
            value: "Plaza Escala",
            label: "Tienda Panini Morelia",
            "Dirección": "Periférico Paseo de la República 5030, Sector Nueva España 58270 Morelia, LOCAL C15 PLAZA ESCALA",
            "Ciudad o Municipio": "Morelia"
        },
        {
            "undefined": "32",
            value: "Patio Hermosillo",
            label: "Tienda Panini Hermosillo",
            "Dirección": "Periférico Oriente 1274, Colonia Perisur 83290 Hermosillo, PATIO HERMOSILLO ",
            "Ciudad o Municipio": "Hermosillo"
        },
        {
            "undefined": "33",
            value: "Los Altos",
            label: "Tienda Panini Los Altos",
            "Dirección": "Morelos 280, CP 47600, Tepatitlan de Morelos, Jalisco",
            "Ciudad o Municipio": "Jalisco"
        },
        {
            "undefined": "34",
            value: "Ciudad Juárez",
            label: "Tienda Panini CD. Juarez",
            "Dirección": "Av. Benjamín Franklin 3220 LOCAL  6H  Col. Margaritas, 32300. Cd. Juárez",
            "Ciudad o Municipio": "Ciudad Juárez"
        },
        {
            "undefined": "35",
            value: "Ensenada",
            label: "Tienda Panini Ensenada",
            "Dirección": "\r\nAV RUIZ No.408-B 22800 Ensenada BAJA CALIFORNIA",
            "Ciudad o Municipio": "Ensenada"
        },
        {
            "undefined": "36",
            value: "Piedras Negras",
            label: "Tienda Panini Piedras Negras",
            "Dirección": "Jimenez 700, Local 21-D Col. Las Fuentes, CP. 26010 Piedras Negras, Coahuila",
            "Ciudad o Municipio": "Piedras Negras"
        },
        {
            "undefined": "37",
            value: "WTC",
            label: "Tienda Panini World Trade Center",
            "Dirección": "Torre WTC, Calle Montecito #38, piso 3, local 8 letra D, Col. Nápoles 03810 Benito Juarez, Distrito Federal, Mexico",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "38",
            value: "Macro Plaza",
            label: "Tienda Panini Oaxaca",
            "Dirección": "Carr. Internacional 2002, Nueva Sta Lucia, 71228 Santa Lucía del Camino, Oax.",
            "Ciudad o Municipio": "Oaxaca"
        },
        {
            "undefined": "39",
            value: "Oaxaca Centro",
            label: "Tienda Panini Oaxaca Centro",
            "Dirección": "Calle Macedonio Alcalá 316, colonia Centro, Oaxaca",
            "Ciudad o Municipio": "Oaxaca"
        },
        {
            "undefined": "40",
            value: "Tuxtla",
            label: "Tienda Panini Tuxtla",
            "Dirección": "Galerías Boulevard, Local E-04, Tuxtla Gutiérrez, Chiapas",
            "Ciudad o Municipio": "Chiapas"
        },
        {
            "undefined": "41",
            value: "Matríz",
            label: "Tienda Panini Merida",
            "Dirección": "Calle 49 #308 planta alta por 40 y 42 Local 8 Plaza Real Villas la Hacienda",
            "Ciudad o Municipio": "Mérida"
        },
        {
            "undefined": "42",
            value: "Mérida Centro",
            label: "Tienda Panini Merida Centro",
            "Dirección": "Calle 64 500c Centro, 97000 Mérida",
            "Ciudad o Municipio": "Mérida"
        },
        {
            "undefined": "43",
            value: "Plaza Olmeca",
            label: "Tienda Panini Villahermosa",
            "Dirección": "Avenida Adolfo Ruiz Cortines #1310, Local 4D ( Plaza Olmeca) Colonia Tabasco 2000, CP 86035, Villahermosa, Tabasco",
            "Ciudad o Municipio": "Villahermosa"
        },
        {
            "undefined": "44",
            value: "Mundo E",
            label: "Tienda Panini Mundo E",
            "Dirección": "Perif. Blvd. Manuel Ávila Camacho 1007, Hab Jardines de Santa Monica, 54050 Tlalnepantla de Baz, Méx.",
            "Ciudad o Municipio": "EDOMEX"
        },
        {
            "undefined": "46",
            value: "Plaza Aragón",
            label: "Tienda Panini Plaza Aragón",
            "Dirección": "Av. Carlos Hank González 120, Rinconada de Aragon, 55140 Ecatepec de Morelos, Méx.",
            "Ciudad o Municipio": "EDOMEX"
        },
        {
            "undefined": "47",
            value: "Parque Jardín Azcapotzalco",
            label: "Tienda Panini Parque Jardín",
            "Dirección": "Av. Jardín 330 (4.38 km) 2790 Ciudad de México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "48",
            value: "Acoxpa",
            label: "Panini Point Acoxpa",
            "Dirección": "Avenida de Las Torres No. 37 Colonia Villa Lázaro Cárdenas 14370 Ciudad de México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "49",
            value: "Friki Plaza Saltillo",
            label: "Tienda Panini Saltillo",
            "Dirección": "Calle Gral. Manuel Pérez Treviño 446, Zona Centro 25000 Saltillo FRIKI PLAZA Saltillo Local 148",
            "Ciudad o Municipio": "Saltillo"
        },
        {
            "undefined": "50",
            value: "Friki Plaza Torreón",
            label: "Tienda Panini Torreón",
            "Dirección": "Hidalgo 1334, 27000 Torreón, Coahuila, FRIKI PLAZA  TORREON LOCAL 410",
            "Ciudad o Municipio": "Torreón"
        },
        {
            "undefined": "51",
            value: "Friki Plaza San Luis",
            label: "Tienda Panini San Luis",
            "Dirección": "Venustiano Carranza 130 Centro Histórico 78000 San Luis Potosí, friki plaza San Luis, Local 262",
            "Ciudad o Municipio": "San Luis"
        },
        {
            "undefined": "52",
            value: "Chihuahua",
            label: "Tienda Panini Chihuahua",
            "Dirección": "Calle Aldama 412a.. A 20 metros de restaurante La Casona 31000 Chihuahua (Chihuahua)",
            "Ciudad o Municipio": "Chihuahua"
        },
        {
            "undefined": "53",
            value: "Durango",
            label: "Tienda Panini Durango",
            "Dirección": "Avenida 20 de Noviembre Pte 900. Zona centro. Durango, Durango. 34000 Durango Centro, Durango, Mexico",
            "Ciudad o Municipio": "Durango"
        },
        {
            "undefined": "54",
            value: "Plaza del Sol",
            label: "Tienda Panini Plaza Del Sol",
            "Dirección": "Av. López Mateos Sur No. 2375 Local 31-A, Zapopan, Jalisco",
            "Ciudad o Municipio": "Guadalajara"
        },
        {
            "undefined": "55",
            value: "Gran Terraza Oblatos",
            label: "Tienda Panini Gran Terraza Oblatos",
            "Dirección": "Av. Cvln. División del Nte. 2700, Oblatos, 44716 Guadalajara, Jal. PRIMER NIVEL LOCAL L-43",
            "Ciudad o Municipio": "Guadalajara"
        },
        {
            "undefined": "56",
            value: "Galerías Valle Oriente",
            label: "Tienda Panini Galerías Valle Oriente",
            "Dirección": "Av Lázaro Cárdenas 1000, Valle del Mirador, 64750 Monterrey, N.L., GALERIAS VALLE ORIENTE",
            "Ciudad o Municipio": "Monterrey"
        },
        {
            "undefined": "57",
            value: "Plaza Cumbres",
            label: "Tienda Panini Plaza Cumbres",
            "Dirección": "Hacienda Peñuelas #6771 Residencial Cumbres las Palmas, 64619 Monterrey, Local 1158 Plaza Cumbres",
            "Ciudad o Municipio": "Monterrey"
        },
        {
            "undefined": "58",
            value: "La Gran Plaza",
            label: "Tienda Panini La Gran Plaza",
            "Dirección": "Av. Vallarta #3959 Local “O-24” centro comercial La Gran Plaza Fashion Mall Col. Don Bosco Vallarta",
            "Ciudad o Municipio": "Guadalajara"
        },
        {
            "undefined": "59",
            value: "Cuautitlán",
            label: "Tienda Panini Cuautitlan",
            "Dirección": "División del Sur 466, Romita, 54800 Cuautitlán, Méx.",
            "Ciudad o Municipio": "EDOMEX"
        },
        {
            "undefined": "60",
            value: "Lomas Verdes",
            label: "Tienda Panini Lomas Verdes",
            "Dirección": "AV. LOMAS VERDES NO. 19 SANTA CRUZ ACATLAN NAUCALPAN 53150 loc. 111, 112 y 113 ",
            "Ciudad o Municipio": "EDOMEX"
        },
        {
            "undefined": "61",
            value: "Friki Plaza Acapulco",
            label: "Tienda Panini Acapulco",
            "Dirección": "Avenida Cuauhtemoc 502, 2do nivel, numero de lote sur norte, colonia Centro C. P. 39300 Acapulco de Juárez, Referencias local 526 y 527 dentro de la Plaza de la Tecnología.",
            "Ciudad o Municipio": "Acapulco"
        },
        {
            "undefined": "62",
            value: "Campestre",
            label: "Tiendas Panini Leon | Campestre",
            "Dirección": "Blvd Campestre 105, local 8, colonia Jardines del Moral",
            "Ciudad o Municipio": "León"
        },
        {
            "undefined": "63",
            value: "Irapuato",
            label: "Tienda Panini Irapuato | Irapuato",
            "Dirección": "BLVD Díaz Ordáz col. Centro, Plaza Fiesra Local 28 F",
            "Ciudad o Municipio": "Irapuato"
        },
        {
            "undefined": "64",
            value: "San Francisco del Rincón",
            label: "Tienda Panini San Fco Del Rincón",
            "Dirección": "Tomás Padilla 122 Local 1, Zona Centro",
            "Ciudad o Municipio": "San Francisco del Rincón"
        },
        {
            "undefined": "65",
            value: "Cumbres",
            label: "Tiendas Panini Leon | Cumbres",
            "Dirección": "Océano Índico 414, Cumbres de la pradera León.",
            "Ciudad o Municipio": "León"
        },
        {
            "undefined": "66",
            value: "Francisco Villa",
            label: "Tiendas Panini Leon | Francisco Villa",
            "Dirección": "Francisco Villa 303, local 2, Col. Bugambilias",
            "Ciudad o Municipio": "León"
        },
        {
            "undefined": "67",
            value: "Celaya",
            label: "Tienda Panini Celaya",
            "Dirección": "Plaza Parque Celata, Eje norponiente 801 local E16, Col. 15 de Mayo",
            "Ciudad o Municipio": "Celaya"
        },
        {
            "undefined": "68",
            value: "Piso 4",
            label: "Tienda Panini Polanco",
            "Dirección": "Isaac Newton #286 piso 4, Polanco 4ta sección",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "69",
            value: "Roma",
            label: "Tienda Panini Roma",
            "Dirección": "Insurgentes sur. 300, col Cuauhtemoc ",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "70",
            value: "Monterrey Centro",
            label: "Tienda Panini San Pedro",
            "Dirección": "VASCONCELOS 202 PTE L-4, Av Bosques del Valle, 66250 San Pedro Garza García",
            "Ciudad o Municipio": "Monterrey"
        },
        {
            "undefined": "71",
            value: "Clavería",
            label: "Tienda Panini Clavería",
            "Dirección": "Heliopolis 51, Clavería 02070 Ciudad de México, México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "72",
            value: "Plaza Las Américas Ecatepec",
            label: "Tienda Panini Las Américas Ecatepec",
            "Dirección": "Local LA-39, Centro Las Américas, Av. Central esq. Av. Primero de Mayo, Fracc. Las Américas 55075 Estado de México",
            "Ciudad o Municipio": "CDMX"
        },
        {
            "undefined": "73",
            value: "Playa del Carmen",
            label: "Tienda Panini Carmen",
            "Dirección": "Plaza las Américas, Playa del carmen",
            "Ciudad o Municipio": "CDMX"
        }
    ]