export default [
    {
      "name":"preg1",
            "pregunta":"¿Qué producto compras más seguido?",
            "respuestas":["Sobres sueltos", "Elite Trainer Box","Pokéball Lata","Premium Collections","Caja especial", "Prelanzamientos"]
    },
    {
      "name":"preg2",
      "pregunta":"¿Cuánto gastas mensualmente en Pokémon TCG?",
      "respuestas":["0  a 100 MXN", "100 a 500 MXN","500 a 1000 MXN","1000 a 5000 MXN","5000 MXN o mas"]
    },
  {
    "name":"preg3",
    "pregunta":"¿Cómo te enteraste de Panini Cup?",
    "respuestas":["Medios de Prensa.", "Facebook.","Grupos de Juego.","Amigos","Otro"]
  },
  {
    "name":"preg4",
    "pregunta":"¿Cuánto tiempo llevas jugando Pokémon TCG?",
      "respuestas":["0 - 1 año.", "2 - 3 años.","4 - 6 años.","Más de 6 años."]
  },
  {
    "name":"preg5",
    "pregunta":"¿En cuántos torneos de Pokémon TCG en línea has participado?",
    "respuestas":["Ninguno, es el primero.", "1.","2 a 5","6 a 10","Más de 10"]
  },
  {
    "name":"preg6",
    "pregunta":"¿Cómo te enteras usualmente de estos torneos?",
    "respuestas":["Medios de Prensa.", "Facebook.","Grupos de Juego.","Amigos.","Otro"]
  },
  {
    "name":"preg7",
    "pregunta":"¿Cuáles son tus contenidos favoritos de Panini?",
    "respuestas":["Tutoriales.", "Consejos.","Unboxing.","Curiosidades.","Memes."]
  },
  {
    "name":"preg8",
    "pregunta":"¿En dónde compras usualmente tus productos Panini?",
    "respuestas":["Tienda online.", "Tienda física."]
  },
  {
    "name":"preg9",
    "pregunta":"¿Cómo te enteras de nuestros nuevos productos?",
    "respuestas":["Facebook.", "Instagram.","Twitter.","Youtube"]
  },
  {
    "name":"preg10",
    "pregunta":"¿Te gustaría enterarte de nuestros nuevos productos antes que nadie?",
    "respuestas":["Sí.", "No."]
  },
]