import React ,{useEffect, useState}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Preguntas from './Preguntas';
import cuestionario from '../dataLists/cuestionario';
import TicketForm from './TicketForm';
import Progress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { enviarRespuestas,erroresDeSeleccion} from '../Redux/preguntasDucks';
import { operarStepper, syncroDataTorneo } from '../Redux/playerTournamentData';

const PreguntasYRes = (props) => {

    const preguntasYResp = cuestionario;
    const dispatch = useDispatch();
  
    const respuestas = useSelector(store=> store.preguntas.respuestas);
    const loadingCuestionario = useSelector(store => store.preguntas.loadingCuestionario);
    const errorRegister = useSelector(store => store.preguntas.error);
    const registroSuccessCuestionario = useSelector(store => store.preguntas.registroSuccessCuestionario);
    const [formErrors, setFormErrors] = useState({});
  
    const sendCuestionario = ( e) =>{
  
      console.log("enviar cuestionario filtro")
        let tempErrorD = {};
      console.log(JSON.stringify(respuestas) )
      console.log("que es=?= " +  Object.keys(respuestas))
       
  
       Object.keys(respuestas).map(function(key,index){
          console.log("valor del si hay algo de resp" +respuestas[key])
          tempErrorD[key] = respuestas[key] === "" ? "Seleccióna un valor" :"";
       });
  
       if(Object.values(tempErrorD).every(x => x === "")){
            console.log("todos los campos estan llenos")
            dispatch(enviarRespuestas());
       }else{
         //dispatch(error)
         console.log("campos vacions" + JSON.stringify(tempErrorD))
         dispatch(erroresDeSeleccion(tempErrorD));     
       }   
    }
  
    useEffect(() => {
        console.log("efecto de registro success")
        if(registroSuccessCuestionario ==="Cuestionario enviado")
        dispatch(operarStepper("suma"))
        return () => {
            //cleanup
        }
    }, [registroSuccessCuestionario])

    return (<><br/>
      <Typography variant="h5" align="center" gutterBottom>
        <strong>Cuestionario de Jugador</strong>
      </Typography>
        <br/><br/>
        {preguntasYResp.map((PregyResp)=>{
          return <Grid item><Preguntas pregunta={PregyResp.pregunta} nombre={PregyResp.name}
          respuestas={PregyResp.respuestas}
        /></Grid>
      })}
            {errorRegister && (<Box mt="20px"><Alert severity="error">{errorRegister}</Alert></Box>)}
            {loadingCuestionario && (<Progress />)}
            {registroSuccessCuestionario && (<Box mt="20px" textAlign="center"><Typography variant="subtitle1" align="center" color="success" />
                                  <Alert autoHideDuration={3000} severity="success">
                                    Registro Correcto
                                </Alert></Box>)}
        {!props.isDisabled ? <Typography  variant="body1" align="left">Formulario Completado</Typography>:<></>}                         
        <Button disabled = {loadingCuestionario || !props.isDisabled} variant ="contained" color="secondary" size="large" onClick={sendCuestionario}>Enviar</Button>
  
    </>)
}

export default PreguntasYRes
