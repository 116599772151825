import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Progress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import PaniniList from '../dataLists/sucursalesPanini';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTicket from './DialogTicket';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import {envioTicket} from '../Redux/playerTournamentData';
import { operarStepper } from '../Redux/playerTournamentData';




const useStyles = makeStyles((theme) => ({
  contenedor:{
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '50%', // Fix IE 11 issue.
      
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        width: '100%', // Fix IE 11 issue.
      }
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      fontSize: '1.2rem',
      paddingLeft: '50px',
      paddingRight: '50px',
    }, subtitulo: {
      opacity: 1
    },
    logotheNest: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      height: 280,
      width: 'auto'
    },
    terminos: {
      display: "flex",
  
    },
    checkBoxTerminos: {
      marginRight: theme.spacing(1)
    }
  }));

  const initialFormValues = {
    ticket: '',
    sucursal: '',
  }


const TicketForm = (props) => {
    const classes = useStyles();

  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState({});
  const PaniniListTiendas = PaniniList;

  const dispatch = useDispatch();

  const dataPlayer = useSelector(store=> store.playerTournamentData.dataPlayer);
  const loadingEnvioTicket = useSelector(store => store.playerTournamentData.loadingENVIOTICKET);
  const errorEnvioTicket = useSelector(store => store.playerTournamentData.errorENVIOTICKET);
  const SuccessENVIOTICKET = useSelector(store => store.playerTournamentData.successENVIOTICKET);



  useEffect(() => {
    console.log("efecto de ticket success")
    if(SuccessENVIOTICKET ==="TICKET ENVIADO")
    dispatch(operarStepper("sumaSTATE2"))
    return () => {
        //cleanup
    }
}, [SuccessENVIOTICKET])


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

    const handleInputChange = e => {
        const { name, value } = e.target
        setFormValues({
          ...formValues,
          [name]: value
        })
      }

      
  const handleSubmit = e => {

    e.preventDefault();

    if (validateForm()) {
      console.log("formulario valido con estos valores")
      console.log("formValues" + JSON.stringify(formValues))
      dispatch(envioTicket(formValues))
      return
    }
    console.log("formulario invalido")
    console.log("formValues" + JSON.stringify(formValues))
   
  }

  
  function validateForm() {

    let tempError = {}
    tempError.ticket = formValues.ticket ? "" : "Este campo es obligatorio."
    tempError.sucursal = formValues.sucursal ? "" : "Este campo es obligatorio."
    
    

    setFormErrors({
      ...tempError
    })

    //hace una comparativa en el objeto temporal para validar las validaciones del formulario
    return Object.values(tempError).every(x => x === "")
  }

  const [open, setOpen] = React.useState(false);
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

    return (
        <Container className={classes.contenedor}>
        <form className={classes.form} noValidate autoComplete="true" onSubmit={handleSubmit}>
          <Grid container minWidth={"50%"} spacing={2} direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" align="left" gutterBottom>
                Selecciona tu Sucursal
            </Typography>
              <TextField
                variant="outlined"
                required
                select
                fullWidth
                size="small"
                id="Sucursal"
                label="Sucursal"
                name="sucursal"
                error={formErrors.sucursal}
                helperText={formErrors.sucursal}
                value={formValues.sucursal}
                onChange={handleInputChange}>
                {PaniniListTiendas.map((option) => (  
                    <MenuItem key={option.value} value={option.value}>
                      { }{"    "}{option.label}
                    </MenuItem>
                ))}
                ))
              </TextField>
             
              <Typography  variant="body2">
                { //console.log(_.find(PaniniListTiendas,[ "value",formValues.sucursal ]).Dirección)
                  formValues.sucursal !== "" &&
                  _.find(PaniniListTiendas,[ "value",formValues.sucursal ]).Dirección
                }
              </Typography>
           
            </Grid>
            
            <Grid item xs={12} sm={12}>
              <Box mb={1} style={{display:"flex", alignItems: 'center',verticalAlign:"middle"}}>
              <Typography variant="h6" align="left"   >
                Ingresa tu Ticket
            </Typography> 
            <Tooltip title="Ejemplo de Ticket">
              <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
                <HelpIcon/>
              </IconButton> 
            </Tooltip>
            </Box>
            <DialogTicket  open={open} onClose={handleClose} />
              <TextField
                autoComplete="fname"
                name="ticket"
                variant="outlined"
                required
                fullWidth
                id="ticket"
                label="Folio de la venta"
                autoFocus
                error={formErrors.ticket}
                helperText={formErrors.ticket}
                value={formValues.ticket}
                onChange={handleInputChange}
                size="small"
              />
               <Typography  variant="body2">Folio de la venta</Typography>
            </Grid>
            
          </Grid>


          {errorEnvioTicket && (<Box mt="20px"><Alert severity="error">{errorEnvioTicket}</Alert></Box>)}
          {loadingEnvioTicket && (<Progress />)}
          {SuccessENVIOTICKET && (<Box mt="20px" textAlign="center"><Typography variant="subtitle1" align="center" color="success" /><Alert severity="success">
            Ticket Enviado, revisaremos el ticket y si es valido te mostraremos en esta sección tu codigo para el torneo así como un correo de confirmación 
          </Alert></Box>)}
          <br/>
          {!props.isDisabled ? 
          <Typography variant="body2" align="center" >
        <p> Una vez que haya sido validado tu ticket <strong>{ dataPlayer.dataticket && dataPlayer.dataticket.ticket}</strong> de la tienda <strong>{ dataPlayer.dataticket && dataPlayer.dataticket.sucursal}</strong>  se enviará por correo el codigo de Battlefy y tambien estará disponible en esta sección. </p>
         </Typography>:<></>} 
          <Grid container item justify="center">

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
              disabled={loadingEnvioTicket || !props.isDisabled} 
            >
              Ingresar Ticket
            </Button>
          </Grid>
          

        </form>
    </Container>
    )
}

export default TicketForm
