import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { registrarDeckStandard,registrarDeckTematico } from '../Redux/playerTournamentData';
import Alert from '@material-ui/lab/Alert';
import Progress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import DeckLists from '../dataLists/deckLists';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { storage } from '../firebase';
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    boton: {
       marginBottom:theme.spacing(2)
    },subtitulos:{
        marginTop:theme.spacing(2)
    },
    InputDeckBox: {
       border: 1,
       borderColor: theme.palette.primary.main,
       display: 'flex',
       flexWrap: 'wrap',
       marginTop: theme.spacing(4),
 
       paddingRight: theme.spacing(2),
       paddingBottom: theme.spacing(4),
       [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(0),
          paddingRight: theme.spacing(0),
 
       }
 
    }, CajaDeckRegistrado: {
       border: 6,
       borderColor: theme.palette.secondary.main,
       display: 'flex',
       flexWrap: 'wrap',
       paddingTop: theme.spacing(3),
       [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(0),
       },
 
 
    }, instrucciones: {
       paddingBottom: theme.spacing(2),
    },
    loadingBar: {
       paddingTop: theme.spacing(1),
       paddingBottom: theme.spacing(1)
 
    }, imageDeckList: {
       alignItems: "center",
       marginTop: theme.spacing(1),
       marginBottom: theme.spacing(1),
       marginRight: 'auto',
       marginLeft: 'auto',
       display: 'block',
       height: 250,
       width: 'auto'
    }, form: {
       width: '100%', // Fix IE 11 issue.
       display: "flex"
    },
    containerItemImage: {
       [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(3),
       },
    },
    TextFieldsTematico:{
       paddingBottom: theme.spacing(3),
       paddingRight: theme.spacing(5),
       paddingLeft: theme.spacing(5),
       [theme.breakpoints.down('sm')]: {
          paddingBottom: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
       },
    }
 }));
 
 const placeHolderDeck = `****** Pokémon Trading Card Game Deck List ******
 
     ##Pokémon - 19
     
     * 1 Dubwool V RCL 153
     * 2 Type: Null UNM 183
     * 2 Silvally-GX CEC 184
     * 1 Crobat V DAA 104
     * 3 Centiskorch V DAA 33
     * 1 Heatran-GX UNM 25
     * 3 Volcanion UNB 25
     * 3 Centiskorch VMAX DAA 342
     * 3 Giant Hearth UNM 197
     * 1 Eldegoss V RCL 19
     * 2 Dedenne-GX UNB 195
     
     ##Trainer Cards - 29
     
     * 2 Cherish Ball UNM 191
     * 4 Quick Ball SSH 179
     * 3 Pokémon Communication TEU 15
     * 1 Mallow & Lana CEC 198
     * 4 Switch SUM 132
     * 4 Welder UNB 189
     * 2 Reset Stamp UNM 206
     * 1 Bird Keeper DAA 159
     * 1 Air Balloon SSH 156
     * 2 Fire Crystal UNB 173
     * 2 Boss's Orders RCL 154
     
     ##Energy - 12
     
     * 1 Heat {R} Energy DAA 174
     * 11 Fire Energy Energy 2
     
     Total Cards - 60
     
     ****** Deck List Generated by the Pokémon TCG Online www.pokemon.com/TCGO ******`;
 
 


const RegDeckStandard = () => {
    const classes = useStyles();

   /*** DECK STANDARD ***/
   //Variables del componente de React Estandard
   const [deck, setDeck] = useState(null);
   const [ptcgoName1,setPtcgoName1] =useState("");
   const [errorPtcgoName,setErrorPtcgoName] =useState(null);
   const [helperTextPtcgoName, setHelperTextPtcgoName] = useState("");
   const [deckReg, setDeckReg] = useState("");
   const [error, setError] = useState(false);
   const [helperText, setHelperText] = useState("");

   //VAriables del componente de Redux Estandard
   const dataPlayer = useSelector(store => store.playerTournamentData.dataPlayer);
   const loadingDeckListStandard = useSelector(store => store.playerTournamentData.loadingENVIO_DECK_STANDARD);
   const errorDeckListStandard = useSelector(store => store.playerTournamentData.errorENVIO_DECK_STANDARD);
   const successDeckListStandard = useSelector(store => store.playerTournamentData.successENVIO_DECK_STANDARD);
   const dispatch = useDispatch();

   //EFECTOS
   //Leer variables cuando se actualiza la zona de Deck Standard
   useEffect(() => {

      if (dataPlayer.dataDeckList)
        setDeckReg(dataPlayer.dataDeckList);

        console.log(deckReg);

      return () => {
         //Debo limpiar algo
         console.log("limpieza de efecto de deckreg")
      }

   }, [dataPlayer]);

   //FUNCIONES DE STANDARD
   const cambiaTexto = (e) => {
      let txt = e.target.value;
      setDeck(txt)
      setError(false)
      setHelperText("")
   }
   const handleInputChange = (e) =>{
        setPtcgoName1(e.target.value)
        setErrorPtcgoName(false)
        setHelperTextPtcgoName("")
   }
   const pegarDeckStandard = () => {
      navigator.clipboard.readText().then(clipText => {
        //clipText = clipText.trim();
        clipText = clipText.replace("\n","" ).replace( "\r","");
        let text = clipText;
        //console.log("importado de texto" + text)
         setDeck( text);
         setError(false)
         setHelperText("")
      });
   }
   const enviarDeckStandard = () => {

      //VALIDACION

        if(ptcgoName1 === "")
        {
            setErrorPtcgoName(true);
            setHelperTextPtcgoName("ingresa tu nombre del juego");
            return 
        }

        if(deck === null){
            setHelperText("Introduce un deck de PTCGO");
            setError(true);
            return
        }
      let line1 = deck.split('\n', 1)[0];
      line1 = line1.replace( "\r","");
      line1 = line1.trim();
      console.log("vamos a validar:" + JSON.stringify(line1));
      console.log(JSON.stringify(line1) === "****** Pokémon Trading Card Game Deck List ******")

      if (line1 == '****** Pokémon Trading Card Game Deck List ******' || line1 == '****** Lista de baraja del Juego de Cartas Coleccionables Pokémon ******')
       {
         //console.log("Deck Valido")
         setHelperText("Formato Correcto")
         setError(false);
         dispatch(registrarDeckStandard(deck,ptcgoName1));
      } else {
         //console.log("El texto de tu portapapeles no es un deck de PTCGO")
         setHelperText("El texto copiado no es un deck de PTCGO");
         setError(true);
      }
   }
    return (
        <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.contenedor}>
            <Grid item xs={12}  >
               <Typography variant="h5" align="center" gutterBottom>
                  <strong>Registro de deck </strong>
               </Typography>
               <Typography variant="body2" align="center" className={classes.instrucciones} >
                  <p> Despues de haber copiado tu deck desde PTCGO al portapapeles
                     Haz click en el boton para pegar tu deck  </p>
               </Typography>
            </Grid>
            <Grid container
               justifyContent="space-evenly" alignItems="center" xs={12} sm={6}>
                    <Typography variant="h6" align="left" gutterBottom>
                           Ingresa tu Nickname del juego PTCGO.
                        </Typography>
                        <TextField
                           name="ptcgoName"
                           variant="outlined"
                           required
                           fullWidth
                           id="PTCGO Name"
                           label="PTCGO Name"
                           size="small"
                           error={errorPtcgoName}
                           helperText={helperTextPtcgoName}
                           value={ptcgoName1}
                           onChange={handleInputChange}
                        />
                         <Typography variant="h6" align="center" gutterBottom className={classes.subtitulos}>
                           Copia y pega tu lista o importala desde el portapapeles.
                        </Typography>
                    <Grid item xs={5} sm={5} lg={4} >
                  <Button variant="contained" color="primary" onClick={pegarDeckStandard}>
                     Importar Deck
                  </Button>
               </Grid>
               
               <Grid item xs={12}  >
                  <TextField
                     id="outlined-textarea"
                     label="Pega tu deck aquí"
                     placeholder={placeHolderDeck}
                     helperText={helperText}
                     error={error}
                     value={deck}
                     maxRows={20}
                     multiline
                     onChange={cambiaTexto}
                     InputLabelProps={{
                        shrink: true,
                     }}
                     variant="outlined"
                     className={classes.InputDeckBox}
                  /> </Grid>
                  <Grid item xs={12} className={classes.loadingBar}>
                  {errorDeckListStandard && (<Box mt="20px">
                     <Alert severity="error">
                        {errorDeckListStandard}
                     </Alert></Box>)}
                  {loadingDeckListStandard && (<Progress className={classes.loadingBar} />)}
                  {successDeckListStandard && (<Box mt="20px" textAlign="center">
                     <Typography variant="subtitle1" align="center" color="success" />
                     <Alert severity="success">
                        {successDeckListStandard}
                     </Alert></Box>)}
               </Grid>
               <Typography paragraph variant="h6" color="primary" align="center">Registro Cerrado</Typography>
                  {/*<Grid item xs={5} sm={5} lg={4}>
                  <Button variant="contained" color="primary" onClick={enviarDeckStandard} >
                     Registrar Deck y NickName
                  </Button>
                  </Grid>*/}
            </Grid>
            <Grid item xs={12} sm={6} >
               <Box >
                  <Typography gutterBottom variant="h6" align="left" >
                     Deck Registrado
                  </Typography>
               </Box>
               {deckReg.dataDeckList === "" ?
                  <Typography gutterBottom variant="body1" align="left" color={"error"}>
                     Aún no ha sido registrado un deck
                  </Typography>
                  : <>
                     <Typography variant="body2" align="left" color={"primary"}>
                        {deckReg && moment.unix(deckReg.horaRegistroDeck.seconds).format("DD/MMM/YYYY , h:mm:ss a")}
                     </Typography>
                     <TextField
                        id="outlined-textarea"
                        placeholder={"deck"}
                        value={deckReg.deckList}
                        InputLabelProps={{
                           shrink: true,
                        }}
                        multiline
                        disabled
                        variant="outlined"
                        className={classes.CajaDeckRegistrado}
                     />
                  </>
               }
            </Grid>
         </Grid>
            
    )
}

export default RegDeckStandard
