import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titulo: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
    terminosPanini: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(0),
    }
}))

const AvisoDePrivacidad = () => {

    const classes = useStyles();

    return (
        <Container maxWidth="lg" >
            <Typography variant="h4" color="primary" gutterBottom className={classes.titulo}>
                AVISO DE PRIVACIDAD - THE NEST
            </Typography>
            <Typography variant="h6" gutterBottom>
                1) PROCESAMIENTO DE DATOS
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Los datos almacenados en “The Nest” son procesados por Zzang Zzang Technologies and Developement S. de R.L. de C.V. en la Ciudad de México y son utilizados únicamente para fines de identificación del Torneo Nacional de Pokémon Trading Card Game Online organizado por Panini México dentro de esta plataforma.<br/>
                De llegar a ser uno de los ganadores del Torneo Nacional de Pokémon Trading Card Game Online se utilizará tu correo electrónico o tu teléfono para conocer tu ciudad mediante un código postal y hacerte llegar los premios al Panini Point que se encuentre más cerca de tu domicilio postal.
                <br/><br/>
            </Typography>
            <Typography variant="h6" gutterBottom>
                2) RECOLECCIÓN DE DATOS DE MENORES DE EDAD
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                En el caso de participantes menores de edad en el Torneo Nacional de Pokémon Trading Card Game Online se solicita en el formulario los datos del padre o tutor con el fin de tener una aprobación de la participación del menor. Así mismo, el ingresar los datos del padre o tutor exime de responsabilidad a Sangsang Technologies and Developement S. de R.L. de C.V. de la participación no supervisada de un menor de edad dentro del torneo.<br/>
                En caso de que el menor de edad participe y gane algún premio y se descubra que el menor no contó con la supervisión o la aprobación del padre o tutor, la participación del menor quedará descalificada, reasignando así la tabla de posiciones y los premios.
                <br/><br/>
            </Typography>
            <Typography variant="h6" gutterBottom>
                3) ELIMINACIÓN DE DATOS
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                En caso de que requieras que tus datos se eliminen del sistema o exista algún cambio de los mismos, envía un correo a contacto@sangsang.mx explicando la petición, junto con algún documento que acredite tu identidad.
                <br/><br/>
            </Typography>
            <Typography variant="h6" gutterBottom>
                4) USO DE COOKIES
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                La plataforma The Nest utilizará cookies dentro de tu explorador. Las cookies son archivos donde se guarda la información de navegación para que tu experiencia en la página sea más sencilla. Esta información puede contener tu acceso al sitio o recordar tus preferencias en la plataforma. Puedes cambiar la manera en que las cookies trabajan mediante las opciones de tu explorador de internet.
                <br/><br/>
            </Typography>
            <Typography variant="h6" gutterBottom>
                5) ENLACES EXTERNOS
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Si en algún momento de tu visita abandonas el sitio por enlaces no pertenecientes a The Nest, Zzangs Zzang Technologies and Developement S. de R.L. de C.V. no se hará responsable de los datos que puedan solicitarse ni de los archivos almacenados en tu ordenador por dichos sitios.   </Typography>
                <br/>
            <Typography variant="h6" gutterBottom>
                6) CONSENTIMIENTO
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Si no manifiestas tu negativa para los datos solicitados dentro de The Nest, Zzang Zzang Technologies and Developement S. de R.L. de C.V. entenderá que has otorgado tu consentimiento y tu aprobación a lo escrito con anterioridad 
                <br/><br/>
            </Typography>
            <Typography variant="h6" gutterBottom>
                 7) MODIFICACIONES DEL AVISO
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                El actual Aviso de Privacidad puede presentar modificaciones. En dicho caso se dará a conocer mediante un banner o una notificación dentro de la plataforma de The Nest.     
                <br/><br/>
            </Typography>
        
       </Container>
    )
}

export default AvisoDePrivacidad
