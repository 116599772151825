import { db, firebaseApp } from "../firebase";

//data Inicial
const dataInicial = {
  dataPlayer: {},
  loadingData: true,
  loadingENVIOTICKET: false,
  errorENVIOTICKET: "",
  successENVIOTICKET: "",
  loadingENVIO_DECK_STANDARD:false,
  successENVIO_DECK_STANDARD:"",
  errorENVIO_DECK_STANDARD:"",
  loadingENVIO_DECK_TEMATICO : false,
  successENVIO_DECK_TEMATICO : "",
  errorENVIO_DECK_TEMATICO:""
};

//types
const SYNCRO_DATAPLAYER_TORNEO = "SYNCRO_DATAPLAYER_TORNEO";
const OPERAR_STEPPER = "OPERAR_STEPPER";
const LOADING_ENVIO_TICKET = "LOADING_ENVIO_TICKET";
const ERROR_ENVIO_TICKET = "ERROR_ENVIO_TICKET";
const OK_ENVIO_TICKET = "OK_ENVIO_TICKET";
const LOADING_ENVIO_DECK_STANDARD = "LOADING_ENVIO_DECK_STANDARD";
const ERROR_ENVIO_DECK_STANDARD = "ERROR_ENVIO_DECK_STANDARD";
const OK_ENVIO_DECK_STANDARD = "OK_ENVIO_DECK_STANDARD";
const LOADING_ENVIO_DECK_TEMATICO = "LOADING_ENVIO_DECK_TEMATICO";
const ERROR_ENVIO_DECK_TEMATICO = "ERROR_ENVIO_DECK_TEMATICO";
const OK_ENVIO_DECK_TEMATICO = "OK_ENVIO_DECK_TEMATICO";

//reducers
export default function playerTournamentDataReducer(state = dataInicial, action) {
  
    switch (action.type) {
    //////////    OPERAR LA UI DEL STEPPER   /////////
    case OPERAR_STEPPER:
      return { ...state, dataPlayer: action.payload };
    case SYNCRO_DATAPLAYER_TORNEO:
      return { ...state, dataPlayer: action.payload, loadingData: false };
    /////////   ENVIO DE TICKET ACTIONS   //////////
    case OK_ENVIO_TICKET:
      return {
        ...state,
        loadingENVIOTICKET: false,
        successENVIOTICKET: action.payload,
      };
    case ERROR_ENVIO_TICKET:
      return { ...state, errorENVIOTICKET: action.payload };
    case LOADING_ENVIO_TICKET:
      return { ...state, loadingENVIOTICKET: true };

    //////////   ENVIO DE DECK STANDARD     //////////////
    case OK_ENVIO_DECK_STANDARD:
      return {
        ...state,
        loadingENVIO_DECK_STANDARD: false,
        dataPlayer: action.payload,
        successENVIO_DECK_STANDARD:"Deck Registrado"
      };
    case ERROR_ENVIO_DECK_STANDARD:
      return { ...state, errorENVIO_DECK_STANDARD: action.payload };
    case LOADING_ENVIO_DECK_STANDARD:
      return { ...state, loadingENVIO_DECK_STANDARD: true };
    /***  ENVIO DE DECK TEMATICO ***/
    case OK_ENVIO_DECK_TEMATICO:
      return {
        ...state,
        loadingENVIO_DECK_TEMATICO: false,
        dataPlayer: action.payload,
        successENVIO_DECK_TEMATICO:"Deck Registrado"
      };
    case ERROR_ENVIO_DECK_TEMATICO:
      return { ...state, errorENVIO_DECK_TEMATICO: action.payload };
    case LOADING_ENVIO_DECK_TEMATICO:
      return { ...state, loadingENVIO_DECK_TEMATICO: true };
    //////////
    default:
      return { ...state };
  }
}

//Actions
export const syncroDataTorneo = () => async (dispatch, getState) => {
  console.log("dispare la action syncro");
  const usuario = getState().usuario;
  let docs;
  try {
    /*
      db.collection(usuario.user.torneo).doc(usuario.user.email).onSnapshot(snapshot =>{
        docs = snapshot.data()//.map( doc => (doc.data()))
        console.log(" docus del jale" + JSON.stringify(docs));
      
             dispatch({ type : SYNCRO_DATAPLAYER_TORNEO,
                        payload : docs
                    })          
    })*/

    docs = await db
      .collection(usuario.user.torneo)
      .doc(usuario.user.email)
      .get();

    dispatch({ type: SYNCRO_DATAPLAYER_TORNEO, payload: docs.data() });
  } catch (error) {
    console.log("algo valio jalando la data del user" + error);
  }
};


export const operarStepper = (operacionx) => (dispatch, getState) => {
  const operacion = operacionx;
  const dataPlayer = getState().playerTournamentData.dataPlayer;
  let dp = getState().playerTournamentData.dataPlayer.paso;
  console.log("entro a operar el stepper");

  console.log("soy null");
  if (operacion === "suma" && dp === 0) {
    dp += 1;
  }
  if (operacion === "sumaSTATE2" && dp === 1) {
    dp += 1;
  }

  try {
    dispatch({
      type: OPERAR_STEPPER,
      payload: { ...dataPlayer, paso: dp },
    });
  } catch (err) {
    console.log("error en el operarStepper" + err);
  }
};

//Formulario Ticket de registro Panini
export const envioTicket = (dataticket) => async (dispatch, getState) => {
  const usuario = getState().usuario;

  dispatch({
    type: LOADING_ENVIO_TICKET,
  });

  try {
    console.log("voy a enviar el ticket" + JSON.stringify(dataticket));
    await db
      .collection(usuario.user.torneo)
      .doc(usuario.user.email)
      .update({
        dataticket: {
          ...dataticket,
          horaRegistroTicket: firebaseApp.firestore.FieldValue.serverTimestamp(),
        },
        paso: 2,
      });

    dispatch({
      type: OK_ENVIO_TICKET,
      payload: "TICKET ENVIADO",
    });
  } catch (err) {
    dispatch({
      type: ERROR_ENVIO_TICKET,
      payload: err.message,
    });
  }
};

//Formulario registro de Deck STANDARD
export const registrarDeckStandard = (deckStandard,ptcgoName1) => async (dispatch, getState) => {
  
    const usuario = getState().usuario;
    const dataPlayerX = getState().playerTournamentData.dataPlayer;

    dispatch({
        type: LOADING_ENVIO_DECK_STANDARD
    });

  try {
    //Enviamos el ticket a la database de jugador
    await db.collection(usuario.user.torneo).doc(usuario.user.email).update({
        dataDeckList: {
          deckList:deckStandard,
          horaRegistroDeck: firebaseApp.firestore.FieldValue.serverTimestamp(),
        },
        ptcgoName:ptcgoName1,
        paso: 3,
    });

    dispatch({
        type: OK_ENVIO_DECK_STANDARD,
        payload: {...dataPlayerX,dataDeckList:{
            deckList:deckStandard,
            horaRegistroDeck: firebaseApp.firestore.FieldValue.serverTimestamp()
            },
            ptcgoName:ptcgoName1,
            paso: 3
        }
    });

  } catch (err) {
    dispatch({
      type: ERROR_ENVIO_DECK_STANDARD,
      payload: err.message,
    });
  }
};

//Formulario registro de Deck TEMATICO
export const registrarDeckTematico = (deckTematico) => async (dispatch, getState) => {
  
  const usuario = getState().usuario;
  const dataPlayerX = getState().playerTournamentData.dataPlayer;

  dispatch({
      type: LOADING_ENVIO_DECK_TEMATICO
  });

try {
  //Enviamos el Deck Tematico a la database de jugador
  await db.collection(usuario.user.torneo).doc(usuario.user.email).update({
      dataDeckList: {
        deckList:deckTematico.deckList,
        horaRegistroDeck: firebaseApp.firestore.FieldValue.serverTimestamp(),
      },
      ptcgoName:deckTematico.ptcgoName,
      paso: 3,
  });

  dispatch({
      type: OK_ENVIO_DECK_TEMATICO,
      payload: {...dataPlayerX,dataDeckList:{
          deckList:deckTematico.deckList,
          horaRegistroDeck: firebaseApp.firestore.FieldValue.serverTimestamp()
          },
          ptcgoName:deckTematico.ptcgoName,
          paso: 3
      }
  });

} catch (err) {
  dispatch({
    type: ERROR_ENVIO_DECK_TEMATICO,
    payload: err.message,
  });
}
};
