import firebaseApp from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBoeXS01HhH_p00hj3WUM_NVFlT4454PSU",
    authDomain: "thenest-panini.firebaseapp.com",
    projectId: "thenest-panini",
    storageBucket: "thenest-panini.appspot.com",
    messagingSenderId: "1090467199886",
    appId: "1:1090467199886:web:52108460795644f9d782df",
    measurementId: "G-2ESHGPMVZH"
  };
  // Initialize Firebase
  firebaseApp.initializeApp(firebaseConfig);
  
  //firebaseApp.analytics();

  const db = firebaseApp.firestore()
  const auth = firebaseApp.auth()
  const storage =  firebaseApp.storage()

  export {db,auth,storage,firebaseApp}
