import React, {useEffect} from 'react';
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography  from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LogoTematico from '../assets/images/ThemeLogo.svg';
import LogoStandard from '../assets/images/StandardLogo.svg';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton'
import StepperRegistro from '../componentes/StepperRegistro';
import { useSelector } from 'react-redux';
import {withRouter} from 'react-router-dom';
import EstadosList from '../dataLists/estados';
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
  root:{
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      flexGrow:1,
    
  },paper:{
      padding:theme.spacing(3),
  },logoTorneo: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    height: 280,
    width: 'auto'
  },datos:{
      paddingTop:theme.spacing(3),
      paddingBottom:theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        textAlign:'center'
        }
    },textoTitulo:{
        [theme.breakpoints.down('xs')]: {
            textAlign:'center'
        }
    },textoData:{
        paddingBottom:theme.spacing(3)
    }
}));


const Jugador = (props) => {

    //Estilos
    const classes =  useStyles();

    const jugador = useSelector(store=> store.usuario);
    const listaEstados = EstadosList;

    useEffect(() => {
        //console.log("EFFECT/LOGIN PAGE - Por decidir" + jugador);
        if(jugador.activo === false){
          //console.log("EFFECT/LOGIN PAGE - User activo es true: " + jugador);
          props.history.push("login")
        }else{
         // console.log("EFFECT/LOGIN PAGE - no empuje el usuario a admin porque no hay user activo" + jugador);
         // props.history.push("login")
         // props.history.push("")
        }
       
      }, [jugador])

    return (<>
        <Container className={classes.root}>
            <Paper className={classes.paper} >
                <Divider/>
                <Grid container>
                    <Grid container item  xs={12} md={6}  justifyContent ="center">

                        { jugador.user && (jugador.user.torneo === "jugadoresStandard" ? 
                        <img src={ LogoStandard} className={classes.logoTorneo}/>
                        : <img src={ LogoTematico } className={classes.logoTorneo}/>
                        )}

                        
                    </Grid>
                    <Grid container  item sm={12} md={6} direction="column"  >
                        <Grid item className={classes.textoTitulo}> 
                            <Typography variant="h4" gutterBottom>
                                <br/> <strong>Datos de Jugador</strong>   
                            </Typography>
                            <Divider/>
                        </Grid>                      
                    <Grid container justifyContent="center" alignItems="center" className={classes.datos}>
                            <Grid item xs={12} sm={6} className={classes.textoData}>
                                    <Typography variant="h5">
                                    <strong> Nombre: </strong> 
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                        { jugador.user &&  " " + jugador.user.firstName + " " + jugador.user.lastName }
                                        { !jugador.user && <Skeleton variant="text"/> }
                                    </Typography>

                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.textoData}>  
                                    <Typography variant="h5">
                                    <strong> Correo:</strong>
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                    { jugador.user && " " + jugador.user.email }
                                    { !jugador.user && <Skeleton variant="text" /> }
                                    </Typography>
                            </Grid>
                            
                            <Grid item xs={12} sm={6} className={classes.textoData}>        
                                <Typography variant="h5">
                                    <strong> Estado: </strong>
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                    
                                        { jugador.user && " " + _.find(listaEstados,["value",jugador.user.estado]).label }
                                        { !jugador.user && <Skeleton variant="text"/> }
                                    </Typography>
                            </Grid>
                                <Grid xs={12} sm={6} className={classes.textoData}>
                                    <Typography variant="h5">
                                    <strong>Torneo:</strong>
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                    { jugador.user && " " + (jugador.user.torneo === "jugadoresStandard" ? "Formato Estandar":"Formato Tematico")  }
                                    { !jugador.user && <Skeleton variant="text" /> }  
                                    </Typography>   
                                </Grid>{/**
                                <Grid item xs={12} sm={6} className={classes.textoData}>        
                                <Typography variant="h5">
                                    <strong> PTCGO Nickname: </strong>
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                    
                                        { jugador.user && " " + jugador.user }
                                        { !jugador.user && <Skeleton variant="text"/> }
                                    </Typography>
                            </Grid>
                                <Grid xs={12} sm={6} className={classes.textoData}>
                                    <Typography variant="h5">
                                    <strong>Torneo:</strong>
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                    { jugador.user && " " + (jugador.user.torneo === "jugadoresStandard" ? "Formato Estandar":"Formato Tematico")  }
                                    { !jugador.user && <Skeleton variant="text" /> }  
                                    </Typography>   
                                </Grid>
                                 ***/}
                        </Grid>
                    </Grid>
                </Grid>
                <Divider/>
            </Paper>
        </Container>
         <StepperRegistro/>
         </>
    )
}

export default withRouter(Jugador)
