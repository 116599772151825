import { db } from "../firebase"

//data Inicial
const dataInicial = {
   
    loading: false,
    loadingCuestionario:false,
    error:"",
    
    respuestas:{
        "preg1":"",
        "preg2":"",
        "preg3":"",
        "preg4":"",
        "preg5":"",
        "preg6":"",
        "preg7":"",
        "preg8":"",
        "preg9":"",
        "preg10":""
    },
    tempError:{
        "preg1":"",
        "preg2":"",
        "preg3":"",
        "preg4":"",
        "preg5":"",
        "preg6":"",
        "preg7":"",
        "preg8":"",
        "preg9":"",
        "preg10":""
    } 
}

//types
const LOADING_PREGS = "LOADING_PREGS"
const UPDATE_RES = "UPDATE_RES";
const ERROR_FORM_RES = "ERROR_FORM_RES";
const ENVIARCUESTIONARIO_ERROR = "ENVIARCUESTIONARIO_ERROR";
const ENVIAR_CUESTIONARIO = "ENVIAR_CUESTIONARIO";



//reducers 
export default function preguntasReducer (state = dataInicial,action){
    switch(action.type){
        case LOADING_PREGS:
            return {...state, loadingCuestionario: true}
        case UPDATE_RES:
            return { ...state, respuestas: action.payload}
        case ERROR_FORM_RES:
            return {...state, tempError: action.payload }
        case ENVIARCUESTIONARIO_ERROR:
            return { ...state, error: action.payload,loadingCuestionario:false}
        case ENVIAR_CUESTIONARIO:
                return { ...state, respuestas: action.payload, loadingCuestionario:false,registroSuccessCuestionario:"Cuestionario enviado"}
        default:
            return{...state}
    }
}

//Actions
export const writeRespuestas = (name,value)=> async (dispatch,getState)=>{
    const respuestas = getState().preguntas.respuestas;
    console.log("dispare la action")
    console.log("En el reducer" + JSON.stringify(respuestas))
    dispatch({
        type: UPDATE_RES,
        payload:{
            ...respuestas,
          [name]: value 
        }
    })
}

export const enviarRespuestas = ()=> async(dispatch,getState)=>{

    const respuestas = getState().preguntas.respuestas;
    const usuario = getState().usuario;

    //despliegue en la UI para que este en loading mientras enviamos info 
    dispatch({
        type: LOADING_PREGS
    })

    try{
        await db.collection(usuario.user.torneo).doc(usuario.user.email).update({
            pregRespuestas: respuestas,
            paso:1
        });

        dispatch({
            type:ENVIAR_CUESTIONARIO,
            payload: respuestas
        })
    

    }catch(error){
        console.log(error);
        dispatch({
            type : ENVIARCUESTIONARIO_ERROR,
            payload: error
        })
    }
}


export const erroresDeSeleccion = (tempErrorX) => (dispatch,getState) =>{

    console.log("Errores de selecion")
    const tempError = getState().preguntas.tempError;

    dispatch({
        type: ERROR_FORM_RES,
        payload:tempErrorX
    })
}