import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titulo: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
    terminosPanini: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(0),
    }
}))



const TerminosyCondiciones = () => {

    const classes = useStyles();



    return (
        <Container maxWidth="lg" >
            <Typography variant="h4" color="primary" gutterBottom className={classes.titulo}>
                TÉRMINOS Y CONDICIONES THE NEST
            </Typography>
            <Typography variant="h6" gutterBottom>
                1) TÉRMINOS
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                1.1 Se denominará “Plataforma” al portal web The Nest www.thenest.bravebirds.mx <br />
                1.2 Se denominará “Servidor” al portal de Discord perteneciente al equipo de Esports “Brave Birds”.<br />
                1.3 Se denominará “Participante” a las personas que ingresen al torneo mediante un registro.<br />
                1.4 Se denominará “Usuario” a las personas que ingresen y busquen información en las siguientes ligas:<br />
                A la Plataforma.<br />
                Al Servidor.
            </Typography>
            <Typography variant="h6" gutterBottom>
                2) CONDICIONES
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                La Plataforma se encuentra registrada y forma parte de la colección de propiedad intelectual de Zzang Zzang Technologies and Developement S. de R.L. de C.V. Queda prohibido copiar, reproducir, volver a publicar, descargar, enviar, transmitir o distribuir el material de la Plataforma para uso comercial.
            </Typography>
            <Typography variant="h6" gutterBottom>
                3) USO DE DATOS
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                El Usuario y Participante que use la Plataforma estará consciente del manejo de datos proporcionados al sitio. El Aviso de Privacidad podrá consultarse en la siguiente liga.
            </Typography>
            <Typography variant="h6" gutterBottom>
                4) TRANSMISIONES
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                A los Participantes que lleguen a Top 8, semifinales y finales se les enviará un link donde se tendrán que conectar para realizar una transmisión pública en los canales de los medios participantes. Se les solicita tener disposición de transmitir sus tableros de juego y una webcam para la realización de entrevistas en tiempo real.
            </Typography>
            <Typography variant="h6" gutterBottom>
                5) ENVÍO DE PREMIOS
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Los premios de los Participantes ganadores se enviarán a la Tienda Panini física más cercana al domicilio de los Participantes. Estos NO se enviarán a domicilio, por lo que se sugiere estar atentos a las notificaciones o correos electrónicos que se les haga llegar a los ganadores para la recolección y reclamación de los premios.
            </Typography>
            <Typography variant="h6" gutterBottom>
                6) PROPIEDAD INTELECTUAL
            </Typography>
            <Typography variant="body1" gutterBottom >
                Zzang Zzang Technologies and Developement S. de R.L. de C.V. reserva y protege el uso de la imagen, contenido y marcas dentro de la Plataforma y el Servidor.
            </Typography>


            <Typography variant="h4" color="primary" gutterBottom className={classes.terminosPanini}>
                TÉRMINOS Y CONDICIONES TIENDA PANINI
            </Typography>
            <Typography variant="subtitle2" gutterBottom className={classes.titulo}>
                Usted está leyendo los términos y condiciones de la página www.tiendapanini.com.mx.
                Lo invitamos a leer atentamente estos términos y condiciones antes de la navegación y el uso de los servicios en nuestra página web y aplicación para smartphones y tabletas (en adelante y conjuntamente "Aplicación"). La realización de un pedido implica el conocimiento y la aceptación de estas condiciones.
                Una vez realizado un pedido, se invita al consumidor a imprimir y conservar estas condiciones generales del contrato, que ya leyó y aceptó.
            </Typography>
            <Typography variant="h6" gutterBottom>
                1) CONDICIONES
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                El sitio es propiedad de Panini. Queda prohibido copiar, reproducir, volver a publicar, descargar, enviar, transmitir o distribuir el material de la Aplicación en cualquier forma, salvo que sea para uso personal. La compra de productos y servicios en línea implica la aceptación plena y sin reservas de estas condiciones generales de venta (las "Condiciones").
                La información puede modificarse o actualizarse sin previo aviso. Panini se reserva el derecho de realizar cambios y/o mejoras en cualquier momento y sin previo aviso, a los productos y/o procedimientos en este sitio. Además Panini tiene el derecho de editar la Aplicación o eliminar contenido o las características de cualquier manera, por cualquier motivo o sin motivo. Panini no asume ninguna responsabilidad sobre la exactitud de la información proporcionada, cuyo uso es bajo su propio riesgo. Además, al proporcionar dicha información, Panini no otorga ninguna licencia sobre ningún copyright, patente o cualquier otro derecho de propiedad intelectual. <br /><br />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2) DEFINICIONES
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                2.1. "Sitio" se refiere a www.tiendapanini.com.mx, sitio de Internet, dedicado a la venta de productos editoriales en línea y los bienes y servicios de Editorial Panini S.A. de C.V. o de terceros.<br />
                2.2. "Cliente" es la persona (física o jurídica) que va a comprar en el sitio, en las condiciones de venta.<br />
                2.3. "Orden" es el formulario de solicitud de la mercancía a la venta, complementado por el Cliente en el Sitio.<br />
                2.4. "Productos" son los bienes y servicios ofrecidos en el Sitio Web, de acuerdo con las condiciones generales de venta.<br />
                2.5. "Precio" es la cantidad de venta de los productos, incluye todos los impuestos.<br />
                2.6. "Contrato" es el contrato a distancia que se relaciona con la venta de los productos, de acuerdo con las condiciones de venta.<br />
                2.7. "Partes" son Panini y el cliente.  <br /><br />
            </Typography>
            <Typography variant="h6" gutterBottom>
                3) TRANSMISIÓN DE INFORMACIÓN Y MATERIAL
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Panini no acepta que el usuario envíe información o material confidencial o de propiedades a través de este sitio, cosa que el usuario ya no debería hacer. El usuario acepta que cualquier información o material que facilite a Panini no se considerará confidencial ni de su propiedad. Ofreciendo tal información o materiales a Panini, el usuario garantiza el derecho irrevocable y sin restricción de Panini para utilizar, reproducir, mostrar, modificar, transmitir y distribuir tal información y material, y además acepta que Panini puede utilizar todas las ideas, conceptos o conocimientos que el usuario le facilite.<br /><br />
            </Typography>
            <Typography variant="h6" gutterBottom>
                4) CONDICIONES GENERALES DE VENTA
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Le animamos a que lea los términos y las condiciones de venta con cuidado antes de proceder con cualquier compra en nuestra página web y aplicación para smartphones y tabletas (en adelante y conjuntamente "Aplicación"). Haciendo una compra, se aceptan plenamente los Términos y Condiciones de Venta.<br />
                Por favor, la compra de productos que se venden en este sitio implica el hallazgo de un contrato de compraventa Editorial Panini México S.A de C.V., (en adelante Panini México) y la orden de compra se procesa en territorio mexicano.<br /><br />
                4.1 Objeto del contrato<br />
                El objeto de este contrato de venta son los productos que se ofrecen en este sitio. Se trata de contratos a distancia que se formalizan a través de redes telemáticas (medios de comunicación a distancia, Internet) y sujetos exclusivamente a las presentes condiciones generales del contrato.<br/>
                4.2 Partes contratantes<br /><br/>
                Las presentes condiciones generales del contrato se refieren exclusivamente a los contratos de venta formalizados entre los consumidores (es decir, toda persona natural que actúe con fines no relacionados con su comercio, negocio o profesión) y Panini México.<br />
                Esto significa que los productos y servicios que se ofrecen a la venta en la Aplicación del Grupo Panini están dirigidos a clientes que compran solamente para uso personal y no para los que compran con fines comerciales (por ejemplo, re-venta, incluso en formas que son diferentes de la forma original, vendidos individualmente o combinados con otros productos, utilizados como premios para concursos y actividades similares, etc.).<br />
                Finalización del contrato y aceptación de las condiciones generales de venta Los contratos de venta relativos a los productos que se ofrecen en este sitio se considerarán finalizados en cuanto Panini México haya registrado la orden de compra por parte del consumidor.<br/><br/>
            </Typography>
            <Typography variant="h6" gutterBottom>
                5) INFORMACIÓN SOBRE LOS PRODUCTOS
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                El sitio ofrece un servicio de venta en línea de bienes y servicios que pueden incluir fascículos, revistas, mangas, cómics y coleccionables. El consumidor adquiere el producto según la descripción y las características técnicas indicadas.  Los productos de la página web están disponibles y se pueden comprar hasta agotar existencias.<br /><br />
            </Typography>
            <Typography variant="h6" gutterBottom>
                6) PRECIOS
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Los precios se expresan en pesos ($). Al momento de la compra, es necesario considerar válido el precio que aparece en el momento de la orden ya que en el caso de que exista alguna reducción del valor para el cliente no se reconocerá ninguna diferencia en la cantidad ya pagada. Panini se reserva el derecho de cambiar los precios en cualquier momento. Por último, los precios de cada modalidad que aparecen en el producto incluyen el costo de envío.<br/>
                Antes de completar el pedido, se mostrará un resumen en el que se especificará el precio unitario de cada producto elegido, el precio total si se solicita más de un producto y los gastos de transporte correspondientes.<br /><br />
            </Typography>
            <Typography variant="h6" gutterBottom>
                7) PAGO
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Los métodos de pago disponibles son: tarjeta de crédito, débito, SPEI y Oxxo Pay.

                7.1. Tarjeta de crédito: el sitio acepta pagos con Visa, Mastercard y American Express. Antes de comprar es necesario revisar en el banco emisor de la tarjeta, que se encuentre habilitada para compras en línea, así como recordar que es necesario introducir correctamente todos los datos. Al optar por pagar con tarjeta de crédito, el importe se carga directamente a la tarjeta en el momento de la orden.
                El pago sólo se puede realizar por el propietario/titular de la tarjeta de crédito.

                7.2. El uso de tarjetas de crédito para compras en línea es práctico y seguro. El sitio se compromete a mantener la seguridad de la información de la tarjeta de crédito. Todos los datos delicados se encriptan durante la transmisión usando SSL (Secure Sockets Layer), adoptado en todo el mundo como el más seguro. Todos los datos se almacenan entonces en servidores seguros y lejos de posibles conexiones de terceros a Internet. Panini, de hecho, no llega a saber el número de tarjeta de crédito de los consumidores, ya que pasa directamente desde la aplicación a la pasarela de pago por lo cual, no puede ser considerado responsable de fraude o uso ilegal de tarjetas de crédito. Es importante verificar que su navegador de Internet es compatible con la tecnología SSL para realizar el pedido.<br /><br />
            </Typography>
            <Typography variant="h6" gutterBottom>
                8) ENTREGA
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                8.1. El bien adquirido se entrega a la dirección geográfica indicada por el consumidor. Cualquier requisito específico deberá ser proporcionado por el consumidor a Panini.<br/>

                Panini entregará la mercancía a la dirección geográfica del consumidor a través de mensajería siempre y cuando, la dirección se encuentre dentro de la cobertura de la mensajería.<br/>

                La entrega de la mercancía se hará a más tardar dentro de 30 días hábiles posteriores a la operación de pago en línea. El envío del primer número adquirido se realizará junto con la segunda entrega. A partir de la tercera entrega, se programan los envíos para que estos sean entregados hasta el día de la salida a puntos de venta del fascículo o producto.<br/>

                Cuando la compra es de la colección completa, se entregan los fascículos que han sido publicados hasta la fecha de la transacción, juntos. A partir de ahí, se hará una entrega mensual.

                Panini no es responsable de la falta de entrega cuando los datos de la dirección proporcionados por el cliente o el número de teléfono están equivocados así como la visita en reiteradas ocasiones al domicilio donde no se encuentre quien reciba la compra. Todos los gastos adicionales derivados de complicaciones en el envío o la falta de entrega serán a cargo del cliente en estos casos.<br/>

                8.2. Los horarios y plazos de entrega son de hasta 15 días hábiles para entregas nacionales con el envío a través de mensajería en servicio regular. Los tiempos no aplican para servicios especiales ni coberturas extendidas. En el domicilio de entrega debe haber siempre una persona para recibir el envío, ya que la mensajería acude dentro de los horarios y días establecidos (Para más información, puede consultar en www.redpack.com.mx)<br/>

                8.3. Panini no asume ninguna responsabilidad por el retraso en la entrega de los bienes, causados ??por el mal funcionamiento de la mensajería y/o en los casos de fuerza mayor, tales como desastres naturales, huelgas, la congestión del tráfico, de los periodos de especial pico ni ninguna causa externa no imputable a la Editorial.<br/>

                8.4. Los productos comprados en la Aplicación están sujetos a las regulaciones de las leyes mexicanas aplicables. Panini es responsable ante el consumidor de cualquier defecto de fabricación del bien entregado.<br/>

                En el caso en que los bienes entregados tengan defectos/imperfecciones inherentes, el consumidor tiene derecho al cumplimiento de los bienes restaurados, o a la sustitución de los mismos o, si esto no es posible, al reembolso del precio pagado por ese ejemplar. La garantía antes citada no se aplica a los bienes que tengan daños, roturas y mal funcionamiento, por el mal uso y ejecución en los mismos por parte del consumidor.<br/>

                8.5. El consumidor está obligado a informar a Panini sobre los defectos inherentes de la mercancía que se manifiesten dentro de los cinco días siguientes después de haber recibido la misma, vía correo electrónico, las faltas de calidad o cantidad de ellas, o que dentro de treinta días, contados desde que las recibió, no le reclamase por causa de vicios internos de las mismas, perderá toda acción y derecho a reclamar por tales causas contra Panini. Lo anterior en base al artículo 383 del Código de Comercio.<br/><br/>

            </Typography>
            <Typography variant="h6" gutterBottom>
                9) ACEPTACIÓN DE COMPRA
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                La validación del pedido por parte del Cliente implica la aceptación de los Términos plenamente y sin reservas. Para validar el pedido, debe hacer clic en el botón "Confirmar". Al hacer clic en "Confirmar" el cliente reconoce expresamente que el pedido implica la obligación de pagar.<br/>

                Panini enviará al cliente una notificación por correo electrónico a la dirección suministrada con la confirmación del pedido, que resume los datos personales y los datos en los productos solicitados, cantidad, costo unitario, el costo relacionado con la contribución, los gastos de envío y el coste total de la orden.<br/><br/>

            </Typography>

            <Typography variant="h6" gutterBottom>
                10) RESPONSABILIDAD
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                10.1. Las fotografías y textos son a modo de ejemplo y pueden deformarse por el producto o cambio sin previo aviso. En consecuencia, Panini no se hace responsable de los errores que resultan de estas imágenes o textos ilustrativos.<br/>

                10.2. Panini no se hace responsable de la violación de este contrato en caso de escasez o indisponibilidad del producto, fuerza mayor, el fracaso, la interrupción o huelga total o parcial de los servicios postales y el transporte y/o telecomunicaciones.<br/>

                10.3. Panini no se responsabiliza del contenido de páginas web o aplicaciones de terceros que puedan estar relacionadas con esta la Aplicación. Si el usuario entra en páginas web o aplicaciones de terceros, aun conteniendo el logo de Panini, debe recordar que Panini no tiene control sobre su contenido. Además, el enlace a una página web o aplicación de terceros no significa que Panini apruebe o asuma la responsabilidad por su contenido o utilización. Si el usuario decide visitar y usar alguna de estas páginas web o aplicaciones y/o descargar archivos, debe tener en cuenta que asume todo el riesgo y que es su responsabilidad tomar todas las medidas de precaución para que no se vea afectado por elementos destructivos como virus, gusanos, Troyanos, caballos de Troya u otros. Panini no estará vinculado en ningún evento por daños, ya sean directos, indirectos, especiales, de consecuencia u otros (incluidos, sin límite, la pérdida de beneficios, interrupción de negocios, pérdida de información, programas u otros datos de tu sistema informático del usuario) relacionados con el uso de la Aplicación, el sitio web o cualquier página web o aplicación relacionada, incluso si Panini ha advertido abiertamente de la posibilidad de tales daños.<br/><br/>

            </Typography>

            <Typography variant="h6" gutterBottom>
                11) PROPIEDAD INTELECTUAL
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Toda la información contenida en el sitio web, todas las marcas reproducidas en el sitio, todos los programas y/o tecnologías proporcionadas en relación con el Sitio están reservados y protegidos.<br/><br/>

            </Typography>


            <Typography variant="h6" gutterBottom>
                12) OBLIGACIONES DEL CLIENTE
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                El Cliente declara y garantiza:<br/>
                (I) Ser mayor de edad;<br/>
                (II) Que los datos suministrados por el mismo para la aplicación del acuerdo son verdaderos y correctos.<br/><br/>
            </Typography>


            <Typography variant="h6" gutterBottom>
                13) PRIVACIDAD
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Toda la información del cliente necesaria para la ejecución del contrato se tramitará de conformidad con lo dispuesto en el Decreto Legislativo n. 2003 n. 196 sobre "Protección de datos personales". De acuerdo con el compromiso y la atención que Panini dedica a la protección de los datos personales, el cliente es informado sobre la forma, el propósito y el alcance de la comunicación y la difusión de sus datos personales y sus derechos, de conformidad con el art. 13 del Decreto Legislativo n. 196/2003.<br/><br/>
            </Typography>
            <Typography variant="h6" gutterBottom>
                14) DEVOLUCIONES
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                No hay devoluciones. En caso de que las revistas lleguen dañadas hay reposición.<br/><br/>
            </Typography>

            <Typography variant="h6" gutterBottom>
                15) COMPETENCIA
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">
                Legislación aplicable
                El presente contrato está sujeto a la legislación mexicana, y las partes se someten expresamente a éstas.

                Jurisdicción
                En el caso de una controversia, las partes se someten a los tribunales de la Ciudad de México, Distrito Federal, renunciando al que pudiera corresponderles por motivo de su domicilio actual, vecino o futuro.<br/><br/>
            </Typography>

            <Typography variant="h6" gutterBottom>
                16) SERVICIO AL CLIENTE
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">

                16.1. Para cualquier información adicional puede enviar un correo a clubpaninimx@panini.com.mx e incluir en el asunto del correo electrónico el tipo de solicitud (por ejemplo: "problema técnico"), el uso de su correo electrónico de registro y explicar su problema.<br/>

                16.2. Tales referencias no se pueden utilizar para solicitar productos del sitio<br/><br/>
            </Typography>

            <Typography variant="h6" gutterBottom>
                IMPORTANTE:
            </Typography>
            <Typography variant="body1" gutterBottom align="justify">

                Panini se reserva el derecho de modificar el número de emisiones periódicas en general, así como de cambiar el orden y la secuencia de las salidas individuales, comunicando con antelación la comunicación del plan de trabajo.<br />

                Derecho de desistimiento
                El consumidor podrá ejercer el derecho de desistimiento. En particular, el consumidor tiene derecho a desistir de cualquier contrato con Panini México y sin penalización alguna y sin especificar el motivo en el plazo de 7 días naturales desde la recepción de la mercancía.<br />

                El derecho de rescisión es la responsabilidad del consumidor en relación con cualquier bien adquirido por él/ella en este sitio, a excepción de los productos de software sellados abiertos por el consumidor después de la entrega, diarios, periódicos o revistas que se ofrecen a la venta en esta Aplicación y con el excepción de los productos personalizados, como los llamados MyPanini T.

                Modalidades para ejercitar el derecho de renuncia
                Tiene derecho a renunciar al contrato de venta en el periodo indicado previamente. En tal caso, envíe una carta certificada con acuse de recibo a la atención de la Oficina de Relaciones con el Cliente:

                Editorial Panini México S.A de C.V. con domicilio en Isaac Newton 282 Piso 1 Col. Chapultepec Morales, CP 11570 México D.F Si las mercancías ya se han entregado, el consumidor tiene que devolverlas a Panini México en los 30 días naturales posteriores a la recepción de las mismas. Las mercancías devueltas a Panini México deben estar completas, incluyendo accesorios, manuales de instrucciones y otros extras suministrados originalmente. Lo mismo es aplicable para el envoltorio original. Además, se debe adjuntar una copia del mensaje de correo electrónico en el que se confirma la recepción del pedido correspondiente.<br />

                Los gastos de envío por la devolución de las mercancías a Panini México corren a cargo del consumidor. Si el consumidor ejerce su derecho de renuncia al contrato según las instrucciones que se dan en la presente cláusula, Panini México efectuará los reembolsos en los 30 días posteriores a la fecha en que el consumidor informó a Panini México de la renuncia mediante cheque postal (emitido en moneda nacional). Si el pago se ha realizado mediante tarjeta de crédito, el reembolso se hará en la tarjeta de crédito del consumidor.<br />

                Panini México se reserva el derecho de rehusar las mercancías que no se devuelvan como se describió previamente. Lo mismo es válido si el consumidor no ha satisfecho completamente los gastos de transporte para el envío de devolución o si no se han respetado las modalidades y los períodos de notificación indicados previamente.

            </Typography>

        </Container>
    )
}

export default TerminosyCondiciones
