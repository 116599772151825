import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	titulo: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(3),
	},
	terminosPanini: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(0),
	},
	tabla: {
		border: "2px", solid: "#000"
	}
}))



const Reglas = () => {

	const classes = useStyles();

	return (
		<Container maxWidth="lg" >
			<Typography variant="h4" color="primary" gutterBottom className={classes.titulo}>
				Reglamento Panini Cup 2021
			</Typography>

			<Typography variant="h5" gutterBottom>
				<strong>1. Introducci&oacute;n</strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p> Grupo Panini te invita a la primera Panini Cup, el torneo de Pok&eacute;mon Juego de Cartas Coleccionables Online que se llevar&aacute; a cabo los d&iacute;as 28 y 29 de agosto del 2021 a partir de las 10 am tiempo de la Ciudad de M&eacute;xico (GMT-5).&nbsp;&nbsp; </p>
				<p>El torneo se divide en dos diferentes categor&iacute;as: &ldquo;Theme League&rdquo; y &ldquo;Standard League&rdquo;, cada una con su propio formato. El torneo se realizar&aacute; completamente en l&iacute;nea a trav&eacute;s del juego Pok&eacute;mon Juego de Cartas Coleccionables (PJCCO o  PTCGO, por sus siglas en ingl&eacute;s)&nbsp; la plataforma de registro The Nest y la plataforma para torneos Battlefy. </p>
				<p>El torneo se jugar&aacute; a lo largo de dos d&iacute;as y se distribuir&aacute; de la siguiente manera: </p>
				<table width='100%' style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
					<tbody >
						<tr>
							<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
								<p><strong>S&aacute;bado 28</strong></p>
							</td>
							<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
								<p><strong>Domingo 29</strong></p>
							</td>
						</tr>
						<tr>
							<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
								<p>10:00 a.m.  <strong>Rondas Suizas.</strong></p>
							</td>
							<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
								<p>10:00 a.m. <strong> Ronda de Top 8 (Cuartos de final).</strong><strong><br /><br /></strong></p>
								<p>11:00 a.m.  <strong>Transmisi&oacute;n en vivo del evento.</strong><br /><br /> </p>
								<p>11:15 a.m.  <strong>Ronda de Top 4 (Semifinales) Theme League.</strong><br /><br /> </p>
								<p>12:15 a.m.  <strong>Final Theme League.</strong></p>
								<br />
								<p>1:30 p.m.  <strong>Ronda de Top 4 (Semifinales) Standard League.</strong></p>
								<br />
								<p>2:30 p.m  <strong>Final Standard League.</strong></p>
							</td>
						</tr>
					</tbody>
				</table>
				<p>* Los horarios pueden cambiar el d&iacute;a del evento. Cualquier comunicado se har&aacute; por la cuenta Club Panini MX en Facebook. </p>
				<p>En esta secci&oacute;n encontrar&aacute;n el reglamento del evento, el cual detalla todas las normas y sanciones establecidas para el evento Panini Cup 2021. </p>
				<p><strong>Es obligaci&oacute;n del jugador conocer este reglamento y cada una de sus normas y sanciones. Al entrar al torneo, todos los participantes aceptaron adherirse a las normas y sanciones establecidas en este reglamento. Aquellos que incurran en el incumplimiento del mismo, podr&aacute;n ser acreedores a sanciones y/o ser dados de baja permanente del evento.</strong></p>
			</Typography>
			<br />
			<Typography variant="h5" gutterBottom>
				<strong> 2. Elegibilidad del jugador (Categor&iacute;as) </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Todos los jugadores que cumplan con los siguientes requisitos son elegibles para participar en Panini Cup 2021: </p>
				<ul>
					<li aria-level="1">Ser mayor de 7 a&ntilde;os de edad.</li>
					<li aria-level="1">Haber comprado $300.00 pesos MXN ó mas en <strong>producto de Pokémon Juego de Cartas Coleccionables</strong> en alguno de los puntos de venta  <strong>Tienda Panini</strong>  o en la tienda en l&iacute;nea  <strong>tiendapanini.com.mx.</strong></li>
					<li aria-level="1">Haber realizado su registro en la plataforma  <strong>The Nest</strong>  en tiempo y forma. </li>
					<li aria-level="1"> Haberse registrado al torneo en la plataforma  <strong>Battlefy</strong> . </li>
				</ul>
				<p> El jugador podr&aacute; elegir jugar en una de dos categor&iacute;as: </p>
				<p><strong>THEME LEAGUE </strong> - Formato de barajas tem&aacute;ticas. </p>
				<p><strong>STANDARD LEAGUE</strong>  - Formato est&aacute;ndar. </p>
				<p> Por ning&uacute;n motivo se aceptar&aacute; que un jugador cambie de categor&iacute;a una vez que se haya registrado. Si alg&uacute;n jugador es descubierto el d&iacute;a del evento en una categor&iacute;a que no le corresponde, ser&aacute; acreedor a una descalificaci&oacute;n inmediata del torneo. </p>
				<p> Si se cometi&oacute; un error al momento del registro, favor de contactar al staff a trav&eacute;s del Discord de Brave Birds antes del d&iacute;a del evento. No se realizar&aacute;n cambios ni correcciones el d&iacute;a del evento.  <br />  <br />  Puedes unirte al discord de Brave Birds a trav&eacute;s de este enlace: <a href="https://discord.gg/BDaZte4SFu"> https://discord.gg/BDaZte4SFu </a></p>
			</Typography>
			<br />
			<Typography variant="h5" gutterBottom>
				<strong> 3. Theme League</strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>La Theme League es la categor&iacute;a que se jugar&aacute; con el formato de barajas tem&aacute;ticas.&nbsp;</p>
				<p>Una baraja tem&aacute;tica es una baraja estructurada y lista para jugar. Estos productos son lanzados directamente por Pok&eacute;mon. Se proveer&aacute; una lista de las barajas permitidas en la secci&oacute;n <strong>3.1 Barajas permitidas.</strong></p>
				<p>Las rondas de la categor&iacute;a Theme League se jugar&aacute;n al &ldquo;Mejor de tres&rdquo; (BO3) , esto quiere decir que el jugador deber&aacute; ganar dos de los tres juegos para ser declarado ganador. Si un jugador gana dos juegos consecutivos, no ser&aacute; necesario jugar el tercer juego.</p>
				<p>El tiempo de ronda es de 50 minutos y cada juego deber&aacute; ser jugado con la opci&oacute;n de <strong>partida cronometrada</strong> <strong>activada</strong>, lo cual limita el tiempo de decisi&oacute;n dentro del juego. Es responsabilidad de ambos jugadores asegurarse que la opci&oacute;n de partida cronometrada est&eacute; activada, para m&aacute;s informaci&oacute;n consultar la secci&oacute;n <strong>5.2B Tiempos de Ronda		</strong>.</p>
				<p>En caso de ocurrir cualquier problema. Los jugadores podr&aacute;n llamar a un juez, el cual se comunicar&aacute; con los jugadores por medio del chat en la plataforma Battlefy.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 3.1 Barajas permitidas</strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Para este torneo, con el fin de limitar el formato a mec&aacute;nicas de juego recientes, &uacute;nicamente ser&aacute;n elegibles las siguientes barajas tem&aacute;ticas:</p>
				<p><a href="https://bulbapedia.bulbagarden.net/wiki/Lost_Thunder_(TCG)"><strong>Truenos Perdidos</strong></a></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Storm_Caller_(TCG)">Invocador de Tormentas</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Blazing_Volcano_(TCG)">Volc&aacute;n en Llamas</a></li>
				</ul>
				<p><a href="https://bulbapedia.bulbagarden.net/wiki/Team_Up_(TCG)"><strong>Unión de Aliados</strong></a></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Relentless_Flame_(TCG)">Llama Implacable</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Torrential_Cannon_(TCG)">Cañón Torrencial</a></li>
				</ul>
				<p><strong>Let&rsquo;s Play</strong></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Let%27s_Play,_Pikachu!_(TCG)">Let's Play, Pikachu!</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Let%27s_Play,_Eevee!_(TCG)">Let's Play, Eevee!</a></li>
				</ul>
				<p><a href="https://bulbapedia.bulbagarden.net/wiki/Unbroken_Bonds_(TCG)"><strong>V&iacute;nculos Indestructibles</strong></a></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Lightning_Loop_(TCG)">Circuito Fulminante</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Battle_Mind_(TCG)">Combate Mental</a></li>
				</ul>
				<p><a href="https://bulbapedia.bulbagarden.net/wiki/Unified_Minds_(TCG)"><strong>Mentes Unidas</strong></a></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Soaring_Storm_(TCG)">Tormenta Creciente</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Laser_Focus_(TCG)">Concentraci&oacute;n L&aacute;ser</a></li>
				</ul>
				<p><a href="https://bulbapedia.bulbagarden.net/wiki/Cosmic_Eclipse_(TCG)"><strong>Eclipse C&oacute;smico</strong></a></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Towering_Heights_(TCG)">Cumbres Sublimes</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Unseen_Depths_(TCG)">Abismos Ocultos</a></li>
				</ul>
				<p><a href="https://bulbapedia.bulbagarden.net/wiki/Sword_%26_Shield_(TCG)"><strong>Espada &amp; Escudo</strong></a></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Rillaboom_Theme_Deck_(TCG)">Baraja Tem&aacute;tica Rillaboom </a>&nbsp;</li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Cinderace_Theme_Deck_(TCG)">Baraja Tem&aacute;tica Cinderace</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Inteleon_Theme_Deck_(TCG)">Baraja Tem&aacute;tica Inteleon</a></li>
				</ul>

				<p><a href="https://bulbapedia.bulbagarden.net/wiki/Rebel_Clash_(TCG)"><strong>Choque Rebelde</strong></a></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Zacian_Theme_Deck_(TCG)">Baraja temática de Zacian</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Zamazenta_Theme_Deck_(TCG)">Baraja temática de Zamazenta</a></li>
				</ul>
				<p><a href="https://bulbapedia.bulbagarden.net/wiki/Darkness_Ablaze_(TCG)"><strong>Obscuridad Incandescente</strong></a></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Galarian_Darmanitan_Theme_Deck_(TCG)">Baraja Tem&aacute;tica Galarian Darmanitan</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Galarian_Sirfetch%27d_Theme_Deck_(TCG)">Baraja Tem&aacute;tica Galarian Sirfetch'd</a></li>
				</ul>
				<p><a href="https://bulbapedia.bulbagarden.net/wiki/Vivid_Voltage_(TCG)"><strong>Voltaje Vivido</strong></a></p>
				<ul>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Charizard_Theme_Deck_(TCG)">Baraja Tem&aacute;tica Charizard</a></li>
					<li aria-level="1"><a href="https://bulbapedia.bulbagarden.net/wiki/Drednaw_Theme_Deck_(TCG)">Baraja Tem&aacute;tica Drednaw</a>&nbsp;</li>
				</ul>
			</Typography>
			<br />
			<Typography variant="h5" gutterBottom>
				<strong> 4. Standard League </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>La Standard League es la categor&iacute;a principal del torneo y se jugar&aacute; con el formato est&aacute;ndar.&nbsp;</p>
				<p>El formato est&aacute;ndar est&aacute; conformado por las cartas de las siguientes expansiones:</p>
				<ul>
					<li aria-level="1">De la expansi&oacute;n <strong>Sol y Luna - Uni&oacute;n de Aliados (TEU)</strong> hasta la m&aacute;s reciente expansi&oacute;n legal para torneos, <strong>Espada y Escudo - Reinado Escalofriante (CRE)</strong>.</li>
					<li aria-level="1">Cartas de promoci&oacute;n SM158 en adelante.</li>
					<li aria-level="1">Cartas de promoci&oacute;n SWSH001 en adelante.</li>
					<li aria-level="1">Destinos Ocultos (exceptuando todas las cartas del subconjunto Tesoro Variocolor, menos Lycanroc-GX SV67/SV94).</li>
					<li aria-level="1">Detective Pikachu.</li>
					<li aria-level="1">Camino de Campeones.</li>
					<li aria-level="1">Destinos Brillantes.</li>
				</ul>
				<p>Las siguientes cartas est&aacute;n baneadas de juego, por lo que est&aacute; prohibido su uso:</p>
				<ul>
					<li aria-level="1">Malignia &amp; Juncom&aacute;n (Sol &amp; Luna&mdash;Eclipse C&oacute;smico, 186/236).</li>
					<li aria-level="1">Mismagius (Sol &amp; Luna&mdash;V&iacute;nculos Indestructibles, 78/214).</li>
				</ul>
				<p>Las rondas de la categor&iacute;a Standard League se jugar&aacute;n al &ldquo;Mejor de tres&rdquo; (BO3) , esto quiere decir que el jugador deber&aacute; ganar dos de los tres juegos para ser declarado ganador. Si un jugador gana dos juegos consecutivos, no ser&aacute; necesario jugar el tercer juego.</p>
				<p>El tiempo de ronda es de 50 minutos y cada juego deber&aacute; ser jugado con la opci&oacute;n de <strong>partida cronometrada</strong> <strong>activada</strong>, lo cual limita el tiempo de decisi&oacute;n dentro del juego. Es responsabilidad de ambos jugadores asegurarse que la opci&oacute;n de partida cronometrada est&eacute; activada, para m&aacute;s informaci&oacute;n consultar la secci&oacute;n <strong>5.2B Tiempos de Ronda</strong>.</p>
				<p>En caso de ocurrir cualquier problema. Los jugadores podr&aacute;n llamar a un juez, el cual se comunicar&aacute; con los jugadores por medio del chat en la plataforma Battlefy.</p>
			</Typography>
			<br />
			<Typography variant="h5" gutterBottom>
				<strong> 5. Reglamento General </strong>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.1 Introducción </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>El torneo Panini Cup 2021 se adhiere al Reglamento oficial de Pok&eacute;mon Juego de Cartas Coleccionables y al C&oacute;digo de Conducta de Play! Pok&eacute;mon. A continuaci&oacute;n se detallar&aacute;n algunas de las normas m&aacute;s importantes, as&iacute; como sus correspondientes sanciones si se incurre en el incumplimiento de las mismas.</p>
				<p>Se espera que los jugadores se adhieran a estas normas y act&uacute;en con una actitud amigable y deportiva.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.2 Rondas de juego </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>El primer d&iacute;a del torneo se jugar&aacute; con el sistema de Rondas Suizas hasta que se determinen los mejores 8 jugadores de cada categor&iacute;a. El n&uacute;mero de rondas depender&aacute; del n&uacute;mero de jugadores registrados y se comunicar&aacute; el d&iacute;a del evento.&nbsp;</p>
				<p>Se har&aacute; un corte a Top 8, lo cual significa que los 8 jugadores con la mejor puntuaci&oacute;n pasar&aacute;n a los cuartos de final que se jugar&aacute;n en el d&iacute;a 2 de juego.&nbsp;</p>
				<p>Todo el torneo se llevar&aacute; a cabo en la plataforma Battlefy. La misma se encargar&aacute; de toda la gesti&oacute;n de pareos y el sistema de puntuaci&oacute;n. </p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.2A Pareos </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Los jugadores deber&aacute;n de presentarse en la plataforma de Battlefy, al menos 30 minutos antes del comienzo del torneo. Deber&aacute;n ver un cron&oacute;metro que indica el inicio del torneo.&nbsp;</p>
				<p>Cuando el cron&oacute;metro llegue a cero, deber&aacute;n hacer check-in para que el sistema les asigne su contrincante. Los jugadores tendr&aacute;n 3 minutos para hacerlo, de lo contrario el sistema les asignar&aacute; la derrota definitiva en la ronda (match loss).</p>
				<p>Una vez hecho el check-in, los jugadores podr&aacute;n ver la informaci&oacute;n de su oponente. A partir de este momento tienen 5 minutos para mandar solicitud de amistad en Pok&eacute;mon juego de cartas coleccionables online y agregar a su oponente. Es responsabilidad de ambos jugadores realizar este paso, por lo que ambos deben hacer lo posible para agregarse como amigos en el juego para lanzar el reto y comenzar la partida.</p>
				<p>Si se est&aacute; jugando en la categor&iacute;a<strong> Theme League</strong>, el reto de juego deber&aacute; ser mandado con la <strong>categor&iacute;a de barajas tem&aacute;ticas </strong>seleccionada y la opci&oacute;n de <strong>partida cronometrada activada</strong>.</p>
				<p>Si se est&aacute; jugando en la categor&iacute;a <strong>Standard League</strong>, el reto de juego de juego deber&aacute; ser mandado con la <strong>categor&iacute;a est&aacute;ndar </strong>seleccionada y la opci&oacute;n de <strong>partida cronometrada activada</strong>.</p>
				<p>Ambos jugadores son responsables de asegurarse que estos dos criterios se cumplan al momento de aceptar el reto de juego. En caso contrario, los jugadores deber&aacute;n de jugar la partida y llamar a un juez a la mesa para reportar el problema. El juez determinar&aacute; si se debe aplicar una sanci&oacute;n a uno o ambos jugadores.</p>
				<p>El jugador que gane un juego, deber&aacute; tomar captura de pantalla del resumen del juego, la cual servir&aacute; como evidencia de que el juego se jug&oacute; y el resultado del mismo.</p>
				<p>Una vez terminados los juegos, se deber&aacute; reportar resultados en la plataforma Battlefy. Para m&aacute;s informaci&oacute;n consultar <strong>5.2G Reporte de resultados</strong>.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.2B Tiempos de ronda </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>En cuanto es anunciada la ronda de juego los jugadores tendr&aacute;n <strong>3 minutos</strong> para realizar el check-in de la ronda, de lo contrario, el sistema le asignar&aacute; ronda perdida (match loss) a los jugadores que no hayan hecho check-in.</p>
				<p>El tiempo de ronda es de <strong>50 minutos</strong>, en los cuales se tendr&aacute;n que jugar hasta 3 juegos. En caso de no terminar alguno de los juegos, se aplicar&aacute;n los criterios de desempate. Consultar<strong> 5.2D Criterios de desempate</strong>.</p>
				<p>Cada juego deber&aacute; ser jugado con la opci&oacute;n de <strong>Partida Cronometrada activada</strong>, lo cual limita el tiempo de decisi&oacute;n dentro del juego. Si el jugador est&aacute; tomando mucho tiempo, el juego le dar&aacute; un cron&oacute;metro de 15 segundos por cada acci&oacute;n que haga. Si el jugador se demora m&aacute;s de estos 15 segundos, el juego pasar&aacute; el turno al contrincante. Si esto se repite, el juego le dar&aacute; juego perdido (game loss) al jugador que se est&aacute; demorando.</p>
				<p>As&iacute; mismo, cada jugador tiene un cron&oacute;metro general de 25 minutos cada uno. Si el cron&oacute;metro de un jugador llega a cero, el juego le dar&aacute; juego perdido (game loss) al jugador y este ser&aacute; el resultado final.</p>
				<p>Si un jugador est&aacute; haciendo uso malintencionado del reloj de juego con el fin de obtener una ventaja, se podr&aacute; llamar a un juez a la mesa. El cual determinar&aacute;, de acuerdo a su criterio, si se est&aacute; realizando una falta de retraso de juego (slow play). El juez determinar&aacute; la sanci&oacute;n si se incurre en una falta, la cual puede ir desde una penalizaci&oacute;n de doble premio (double prize penalty) hasta descalificaci&oacute;n del torneo (si se presenta repetidas veces durante el torneo). Para m&aacute;s informaci&oacute;n acerca de las sanciones consultar <strong>5.4B Penalizaciones</strong>.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.2C Mejor de tres </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Todas las rondas, se jugar&aacute;n al mejor de tres (BO3, por sus siglas en ingl&eacute;s). Lo cual quiere decir, que el jugador deber&aacute; ganar dos de los tres juegos para ser declarado ganador de la ronda.</p>
				<p>Si un jugador gana dos juegos consecutivos, no ser&aacute; necesario jugar el tercer juego, dado que se cumple la condici&oacute;n de mejor de tres.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.2D Criterios de desempate </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Los jugadores tienen 50 minutos de ronda para terminar hasta 3 juegos. En caso de que el tiempo de ronda termine y los jugadores se encuentren jugando uno de los 3 juegos, se proceder&aacute; a aplicar los siguientes criterios de desempate para determinar al ganador de la ronda:</p>
				<ul>
					<li aria-level="1"><strong>Primer juego:</strong> Si en los 50 minutos establecidos no se logra concluir el primer juego y no hay un claro ganador, se otorgar&aacute; <strong>ronda perdida a ambos jugadores</strong> (double loss).</li>
					<li aria-level="1"><strong>Segundo juego:</strong> Si en los 50 minutos establecidos no se logra concluir el segundo juego, se le otorgar&aacute; la victoria al jugador que haya <strong>ganado el primer juego</strong>.</li>
				</ul>
				<p><strong>Tercer juego:</strong> Si en los 50 minutos establecidos no se logra concluir el tercer juego, se le otorgar&aacute; la victoria al jugador que haya <strong>ganado el primer juego</strong>.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.2E Concesiones y empates deliberados </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>En este torneo no existen empates. Es decir que todas las rondas deber&aacute;n ser jugadas conforme a las reglas establecidas para el torneo. En caso de que en los 50 minutos de ronda no se concluya cualquiera de los tres juegos, se deber&aacute;n de aplicar los criterios de desempate establecidos en <strong>5.2D Criterios de desempate.</strong></p>
				<p>Si los jugadores, a pesar de que se termin&oacute; el tiempo de ronda, pueden determinar un claro ganador de acuerdo al estado de juego, se podr&aacute; llegar a un acuerdo de concesi&oacute;n de juego. <strong>&Uacute;nicamente si ambos jugadores est&aacute;n completamente de acuerdo con el resultado.</strong> Si uno de los jugadores no est&aacute; de acuerdo, favor de consultar los criterios de desempate establecidos en <strong>5.2D Criterios de desempate</strong>.</p>
				<p>Los <strong>empates deliberados est&aacute;n completamente prohibidos</strong> y todas las rondas deber&aacute;n ser jugadas conforme a las reglas establecidas. Si uno o ambos jugadores intentan arreglar la partida, uno o ambos ser&aacute;n sancionados de acuerdo al criterio del juez central con base en las evidencias presentadas. La penalizaci&oacute;n es la descalificaci&oacute;n del torneo. Para m&aacute;s informaci&oacute;n, consultar <strong>5.4B Penalizaciones</strong>.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.2F Desconexiones </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Al tratarse de un juego en l&iacute;nea. Las desconexiones pueden ocurrir en Pok&eacute;mon Juego de Cartas Coleccionables Online. Por lo que es necesario tipificar las desconexiones de acuerdo a su naturaleza.&nbsp;</p>
				<ul>
					<li aria-level="1"><strong>Desconexi&oacute;n accidental</strong>: la desconexi&oacute;n accidental se da cuando no hay intenci&oacute;n por parte del jugador de desconectarse del juego. Si el jugador logra reingresar al juego y vuelve a la partida en curso, no se aplicar&aacute; ning&uacute;n tipo de sanci&oacute;n. Si el jugador ingresa al juego y no es reconectado a la partida en curso, se le asignar&aacute; juego perdido (game loss), sin excepciones. Es responsabilidad del jugador que permanece en la partida tomar evidencias de la desconexi&oacute;n y tomar captura de pantalla del resumen del juego.&nbsp;</li>
				</ul>
				<ul>
					<li aria-level="1"><strong>Desconexi&oacute;n intencional:</strong> la desconexi&oacute;n intencional es cuando un jugador se desconecta deliberadamente del juego. Si un jugador se desconecta deliberadamente se le asignar&aacute; juego perdido (game loss). Es responsabilidad del jugador que permanece en la partida tomar evidencias de la desconexi&oacute;n y tomar captura de pantalla del resumen del juego.</li>
				</ul>
				<ul>
					<li aria-level="1"><strong>Desconexi&oacute;n simult&aacute;nea o desconexi&oacute;n del servidor:</strong> Si el servidor de PJCCO se desconecta por alguna raz&oacute;n ajena a los jugadores, y ambos jugadores pierden la conexi&oacute;n a PJCCO al mismo tiempo. Se deber&aacute; llamar a un juez a la mesa y este les asignar&aacute; un empate t&eacute;cnico dando as&iacute; una victoria a cada jugador. Este caso es extraordinario y se tiene que mostrar pruebas de que ha existido una doble desconexi&oacute;n por error del servidor, como una captura de pantalla con estampa de tiempo de ambos jugadores.</li>
				</ul>
				<p>Si el juez descubre cualquier tipo de abuso de esta regla o un acuerdo entre ambos jugadores, esto es considerado como un empate deliberado y ambos ser&aacute;n acreedores a una sanci&oacute;n de acuerdo al criterio del juez. Para m&aacute;s informaci&oacute;n consultar <strong>5.2E Concesiones y empates deliberados</strong>.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.2G Reporte de resultados y puntuación </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Ambos jugadores son responsables de reportar los resultados de ronda. Para hacerlo, deben seleccionar el bot&oacute;n de reportar resultados en Battlefy y seleccionar al jugador que gan&oacute; cada juego. Ambos jugadores deben corroborar que la informaci&oacute;n sea la correcta, de lo contrario deber&aacute;n llamar a un juez a la mesa.</p>
				<p>Solamente existen dos resultados posibles para la ronda, los cuales otorgan los siguientes puntos:</p>
				<ol>
					<li aria-level="1"><strong>Victoria</strong>: 3 puntos</li>
					<li aria-level="1"><strong>Derrota</strong>: 0 puntos</li>
				</ol>
				<p>En este torneo no existen los empates, para m&aacute;s informaci&oacute;n consultar <strong>5.2E Concesiones y empates deliberados</strong>.</p>
				<p>Si existe alg&uacute;n error en la puntuaci&oacute;n o resultados de alguna ronda. Es responsabilidad del jugador ponerse en contacto con un juez a trav&eacute;s del bot&oacute;n llama a un juez en Battlefy. </p>
			</Typography>


			<Typography variant="h6" gutterBottom>
				<strong> 5.2H Baja voluntaria del torneo </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Los jugadores que lo deseen podr&aacute;n darse de baja voluntariamente durante el transcurso del evento. Para hacerlo simplemente deber&aacute;n llamar a un juez a la mesa de su &uacute;ltimo juego y confirmar que desean darse de baja del torneo.</p>
				<p><strong>Una vez que se da de baja a un jugador, este no podr&aacute; reingresar al torneo por ning&uacute;n motivo.</strong></p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.3 Listas de baraja estándar </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>La categor&iacute;a Standard League jugar&aacute; en el formato est&aacute;ndar y los jugadores deber&aacute;n subir la lista de su baraja a la plataforma <strong>The Nest</strong> antes del torneo.&nbsp;</p>
				<p>La lista deber&aacute; ser exportada directamente del juego Pok&eacute;mon Juego de Cartas Coleccionables Online. Para hacerlo, el jugador debe ir a la pesta&ntilde;a de <strong>Gestor de barajas</strong>, seleccionar la baraja de que desean usar para el torneo y seleccionar el bot&oacute;n de <strong>Exportar baraja</strong> en la parte superior derecha.&nbsp;</p>
				<p>Los jugadores podr&aacute;n hacer cambios a su baraja a trav&eacute;s de la plataforma <strong>The Nest</strong> hasta el d&iacute;a previo al evento. El d&iacute;a del evento, las barajas estar&aacute;n bloqueadas y los jugadores deber&aacute;n jugar el torneo en su totalidad con la misma baraja.</p>
				<p>El torneo se jugar&aacute; con listas abiertas, es decir, que los jugadores podr&aacute;n ver la lista de su contrincante en la plataforma de Battlefy.&nbsp;</p>
				<p>Para asegurar que los jugadores no realicen cambios a su lista durante el torneo o que existan discrepancias entre la lista registrada y la que est&aacute;n jugando, los jugadores deber&aacute;n de tener la opci&oacute;n de <strong>compartir lista de baraja con todos </strong>en Pok&eacute;mon Juego de Cartas Coleccionables Online. Para activar esta opci&oacute;n, el jugador debe de seleccionar el <strong>bot&oacute;n de Opciones</strong> en el men&uacute; principal y seleccionar<strong> ajustes</strong>. Bajo la pesta&ntilde;a de Juego, podr&aacute;n encontrar la secci&oacute;n de <strong>compartir lista de baraja</strong> y deber&aacute;n seleccionar la opci&oacute;n de <strong>compartir con todos</strong>.&nbsp;</p>
				<p>Si un jugador no comparte su lista de baraja, se podr&aacute; llamar a un juez, quien asignar&aacute; la sanci&oacute;n correspondiente de acuerdo a su criterio. Desde una advertencia (warning) hasta la descalificaci&oacute;n del torneo si se incurre en la falta m&uacute;ltiples veces. Para m&aacute;s informaci&oacute;n consultar <strong>5.4B Penalizaciones</strong>.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.3A Legalidad de la baraja </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>La legalidad de la baraja para la categor&iacute;a <strong>Standard League</strong> est&aacute; dictada por el formato est&aacute;ndar oficial de Play! Pok&eacute;mon para 2021. El cual est&aacute; conformado por las siguientes expansiones:</p>
				<ul>
					<li aria-level="1">De la expansi&oacute;n Sol y Luna - Uni&oacute;n de Aliados (TEU) hasta la m&aacute;s reciente expansi&oacute;n legal para torneos, Espada y Escudo - Reinados Escalofriantes (CRE).</li>
					<li aria-level="1">Cartas de promoci&oacute;n SM158 en adelante.</li>
					<li aria-level="1">Cartas de promoci&oacute;n SWSH001 en adelante.</li>
					<li aria-level="1">Destinos Ocultos (exceptuando todas las cartas del subconjunto Tesoro Variocolor, menos Lycanroc-GX SV67/SV94).</li>
					<li aria-level="1">Detective Pikachu.</li>
					<li aria-level="1">Camino de Campeones.</li>
					<li aria-level="1">Destinos Brillantes.</li>
				</ul>
				<p>Debido a que Play! Pok&eacute;mon requiere que una expansi&oacute;n tenga dos semanas en el mercado a partir de la fecha de lanzamiento para ser considerada legal para torneo. La nueva expansi&oacute;n Espada y Escudo - Cielos Evolutivos <strong>no ser&aacute; legal</strong> para Panini Cup 2021.</p>
				<p>Existe una lista de cartas no permitidas. Las siguientes cartas est&aacute;n baneadas de juego, por lo que est&aacute; prohibido su uso:</p>
				<ul>
					<li aria-level="1">Malignia &amp; Juncom&aacute;n (Sol &amp; Luna&mdash;Eclipse C&oacute;smico, 186/236).</li>
					<li aria-level="1">Mismagius (Sol &amp; Luna&mdash;V&iacute;nculos Indestructibles, 78/214).</li>
				</ul>
				<p>La lista puede cambiar en d&iacute;as previos al evento. Cualquier cambio a la lista de cartas no permitidas ser&aacute; comunicado a trav&eacute;s de la p&aacute;gina de Club Panini MX en Facebook.</p>
				<p>Si se sorprende a alg&uacute;n jugador con cartas no permitidas en su lista, se har&aacute; acreedor a una sanci&oacute;n de acuerdo al criterio del juez. Esta podr&iacute;a ir desde un juego perdido (game loss) hasta la descalificaci&oacute;n del torneo.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.4 Normas y penalizaciones </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Panini Cup se adhiere al reglamento y c&oacute;digo de conducta de jugador establecido por Play! Pok&eacute;mon y busca fomentar un esp&iacute;ritu de competici&oacute;n sana a lo largo del evento. Por lo que, todo el equipo de organizaci&oacute;n y el equipo de jueces del evento se comprometen a respetar los valores fomentados por Play! Pok&eacute;mon y hacer que se cumplan las normas establecidas en este reglamento.</p>
				<p>Inevitablemente, ya sea intencionadamente o no, pueden ocurrir incidentes que incumplan con el reglamento de juego o c&oacute;digo de conducta. En tal caso, los jugadores involucrados ser&aacute;n acreedores a una penalizaci&oacute;n de acuerdo al criterio del juez en turno.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.4A Código de conducta </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Este es un torneo amistoso entre todas las partes correspondientes que participan en este evento. La responsabilidad de la interacci&oacute;n de los jugadores queda a total responsabilidad de los mismos, si existe alg&uacute;n conflicto entre ambos jugadores, o existe alguna discrepancia entre ambos, es responsabilidad de los mismos reportarlo a un Juez para aclarar la situaci&oacute;n. As&iacute; mismo, los jugadores deber&aacute;n aceptar y acatar las instrucciones de los jueces y el staff del evento.</p>
				<p>Panini y The Nest no est&aacute;n en la obligaci&oacute;n, de acuerdo a las leyes mexicanas, de responder o hacer frente a cualquier sanci&oacute;n que sea causada por origen de alg&uacute;n participante de este evento, ya que al aceptar los t&eacute;rminos y condiciones del mismo queda a total responsabilidad del jugador y de sus tutores, en caso de alg&uacute;n menor involucrado, de responsabilizarse por los actos del mismo.</p>
				<p>Panini tiene una pol&iacute;tica de cero tolerancia en contra de la discriminaci&oacute;n y acoso. Si existe alg&uacute;n caso de acoso, abuso, insulto, rac&iacute;smo o discriminaci&oacute;n por parte de alg&uacute;n jugador o alg&uacute;n otro participante del mismo en el evento, esta persona <strong>QUEDAR&Aacute; INMEDIATAMENTE DESCALIFICADA</strong> del evento.</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.4B Penalizaciones </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>Si alg&uacute;n jugador incurre en una falta al reglamento del torneo. Este ser&aacute; acreedor a una penalizaci&oacute;n de acuerdo al criterio de los jueces en turno.</p>
				<p>Existen 6 tipos de penalizaci&oacute;n:</p>
				<p><strong>1. Advertencia (Warning).</strong></p>
				<p>Una advertencia es un llamado de atenci&oacute;n al jugador que cometi&oacute; la falta. No requiere de ninguna acci&oacute;n posterior por parte del jugador, sin embargo, queda una constancia de la advertencia en el historial del jugador durante el evento. </p>
				<p><strong>2. Penalizaci&oacute;n de premio doble (Double Prize Penalty).</strong></p>
				<p>Una penalizaci&oacute;n de premio doble, como su nombre lo indica, es una penalizaci&oacute;n en la que el jugador penalizado pierde dos premios. Esto quiere decir que su oponente debe tomar dos premios menos para ganar la partida.&nbsp;</p>
				<p>Debido a la naturaleza de Pok&eacute;mon Juego de Cartas Coleccionables Online, para aplicar la penalizaci&oacute;n ser&aacute; necesario la cooperaci&oacute;n de ambos jugadores para terminar la partida en cuanto se cumpla la condici&oacute;n de gane. En este caso, que el oponente del jugador penalizado tome 4 premios. Se deber&aacute; tomar una captura de pantalla de la condici&oacute;n de gane antes de culminar el juego, como evidencia de que se cumpli&oacute; la penalizaci&oacute;n.</p>
				<p><strong>3. Penalizaci&oacute;n de premio cu&aacute;druple (Quadruple Prize Penalty).</strong></p>
				<p>Una penalizaci&oacute;n de premio cu&aacute;druple, como su nombre lo indica, es una penalizaci&oacute;n en la que el jugador penalizado pierde cuatro premios. Esto quiere decir que su oponente debe tomar cuatro premios menos para ganar la partida.&nbsp;</p>
				<p>Debido a la naturaleza de Pok&eacute;mon Juego de Cartas Coleccionables Online, para aplicar la penalizaci&oacute;n ser&aacute; necesario la cooperaci&oacute;n de ambos jugadores para terminar la partida en cuanto se cumpla la condici&oacute;n de gane. En este caso, que el oponente del jugador penalizado tome 2 premios. Se deber&aacute; tomar una captura de pantalla de la condici&oacute;n de gane antes de culminar el juego, como evidencia de que se cumpli&oacute; la penalizaci&oacute;n.</p>
				<p><strong>4. Juego perdido (Game Loss).</strong></p>
				<p>Una penalizaci&oacute;n de juego perdido significa que el jugador penalizado pierde un juego a causa de la falta que cometi&oacute;. Sin embargo, se aplica la regla del mejor de tres, es decir que si alguno de los dos jugadores no ha ganado dos juegos. Se sigue jugando la ronda hasta que se encuentre al ganador.</p>
				<p><strong>5. Ronda perdida (Match Loss).</strong></p>
				<p>Una penalizaci&oacute;n de ronda perdida significa que el jugador penalizado pierde autom&aacute;ticamente la ronda, sin importar el estado de la ronda.&nbsp;</p>
				<p><strong>6. Descalificaci&oacute;n.</strong></p>
				<p>La descalificaci&oacute;n es la penalizaci&oacute;n m&aacute;s fuerte e implica la baja definitiva del jugador en el torneo.</p>
				<p><strong>Apelaci&oacute;n de infracciones</strong></p>
				<p>Si un jugador no est&aacute; de acuerdo con la penalizaci&oacute;n que le fue asignada por el juez de mesa, el jugador tendr&aacute; la oportunidad de apelar la decisi&oacute;n ante el Juez Central. El Juez Central, se encargar&aacute; de examinar el caso y determinar una resoluci&oacute;n. Las penalizaciones impartidas por el Juez Central son finales.&nbsp;</p>
				<p><strong>Repetici&oacute;n de infracciones</strong></p>
				<p>Si un jugador incurre en m&aacute;s de una falta a las reglas durante el evento. Se har&aacute; acreedor a penalizaciones m&aacute;s severas de acuerdo al criterio del juez y a la severidad de las faltas cometidas. Por ejemplo, si un jugador acumula dos Advertencias, podr&iacute;a ser acreedor a un juego perdido.&nbsp;</p>
			</Typography>

			<Typography variant="h6" gutterBottom>
				<strong> 5.4C Casos específicos </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p><strong>Personificaci&oacute;n de un jugador</strong></p>
				<p>En caso de ser detectado un caso de personificaci&oacute;n de un jugador o Ghosting, es decir, que un jugador sea sorprendido jugando en lugar de otro jugador. El jugador ser&aacute; descalificado.</p>
				<p><strong>Ayuda externa o Coaching</strong></p>
				<p>En caso de ser detectado un caso de que un tercero est&eacute; ayudando a un jugador durante el torneo. El jugador ser&aacute; descalificado.</p>
				<p><strong>Fallas en el juego (Bugs)</strong></p>
				<p>Si alg&uacute;n jugador usa malintencionadamente alguno de los fallos del juego, mejor conocidos como bugs, para ganar alguna ventaja sobre su oponente, ser&aacute; acreedor a una penalizaci&oacute;n a criterio del juez dependiendo de la severidad de la falta y el estado de juego. Si se sorprende al jugador incurriendo en la misma falta en rondas subsecuentemente, el jugador ser&aacute; descalificado.</p>
			</Typography>
			<br />
			<Typography variant="h5" gutterBottom>
				<strong> 6. Transmisión oficial en vivo </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<p>El evento contar&aacute; con una transmisi&oacute;n en vivo de los juegos del segundo d&iacute;a del evento, por lo que es necesario contar con la disposici&oacute;n de todos los participantes que lleguen a este punto del torneo de transmitir sus partidas con el personal de organizaci&oacute;n del torneo.</p>
				<p>Los jugadores que pasen al Top 8 de cada categor&iacute;a, ser&aacute;n invitados a el canal de Discord de Brave Birds para facilitar la comunicaci&oacute;n para las rondas m&aacute;s importantes del evento. Por lo que se requiere la cooperaci&oacute;n de los participantes que lleguen a este punto para acatar las instrucciones del personal del evento.</p>
				<p>Durante la transmisi&oacute;n se transmitir&aacute;n las siguientes rondas de juego:</p>
				<ul>
					<li aria-level="1">Ronda de Top 4 (Semifinales) de la categor&iacute;a Theme League.</li>
					<li aria-level="1">Final de la categor&iacute;a Theme League.</li>
					<li aria-level="1">Ronda de Top 4 (Semifinales) de la categor&iacute;a Standard League.</li>
					<li aria-level="1">Final de la categor&iacute;a Standard League.</li>
				</ul>
			</Typography>
			<br />
			<Typography variant="h5" gutterBottom>
				<strong> 7. Premiación </strong>
			</Typography>
			<Typography variant="body1" gutterBottom align="justify">
				<p>La premiación se llevará a cabo en los días posteriores al evento, los ganadores deberán acudir a la Tienda Panini en donde compraron su producto de Pokémon Juego de Cartas Coleccionables. Si se compró el producto en Tienda Panini en Línea,
					se acordará con el jugador recoger su premio en la Tienda Panini más conveniente para el mismo.</p>

				<p dir="ltr" >Standard League</p>

				
					<table width='100%' style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
						<tbody>
							<tr >
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
									1er Lugar
								</td>
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>      
								1 Nintendo Switch, 50 sobres de Pok&eacute;mon TCG (Expansiones variadas), 1 Boxset Pok&eacute;mon Black &amp; White , 1 Revista Pok&eacute;mon
								</td>
							</tr>
							<tr >
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>      
								 2do Lugar
								</td>
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>    
								  20 sobres de Pok&eacute;mon TCG (Expansiones variadas), 1 Colecci&oacute;n completa del manga Pok&eacute;mon RGB &nbsp;(3 tomos), 1 Revista Pok&eacute;mon
								</td>
							</tr>
							<tr>
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>      
								3er Lugar
								</td>
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>     
								10 sobres de Pokémon TCG (Expansiones variadas), 1 colección completa del manga Pokémon Yellow (4 tomos), 1 Revista Pokémon
								</td>
							</tr>
						</tbody>
					</table>
				

					<p dir="ltr" >Theme League</p>
					<table width='100%' style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
						<tbody>
							<tr>
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>   
								  1er Lugar
								</td>
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>   
								1 Nintendo Switch LITE, 50 sobres de Pok&eacute;mon TCG (Expansiones variadas), 1 Boxset Pok&eacute;mon Black &amp; White, 1 Revista Pok&eacute;mon
								</td>
							</tr>
							<tr >
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>   
								  2do Lugar
								</td>
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}> 
								     15 sobres de Pok&eacute;mon TCG (Expansiones variadas), 1 Colecci&oacute;n completa del manga Pok&eacute;mon RGB &nbsp;(3 tomos), 1 Revista Pok&eacute;mon
								</td>
							</tr>
							<tr >
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}> 
								     3er Lugar
								</td>
								<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}> 
								     10 sobres de Pok&eacute;mon TCG (Expansiones variadas), 1 colecci&oacute;n completa del manga Pok&eacute;mon Yellow (4 tomos), 1 Revista Pok&eacute;mon
								</td>
							</tr>
						</tbody>
					</table>
			
				<p>
				<Typography variant="h6" gutterBottom>
				<strong> Criterio de Elecci&oacute;n de Tercer Lugar </strong>
			</Typography>
				
				El tercer lugar ser&aacute; elegido de acuerdo al posicionamiento que se obtuvo antes del corte a Top 4. Es decir, si un jugador pas&oacute; en posici&oacute;n 2 antes del corte a Top 4 y el otro pas&oacute; en posici&oacute;n 3. El jugador de posici&oacute;n 2 ser&aacute; elegido como nuestro 3er ganador y el jugador que pas&oacute; en posici&oacute;n 3, ser&aacute; el cuarto lugar.
				</p>
			</Typography>
			<br />

			<Typography variant="h5" gutterBottom>
				<strong> 8. Historial de cambios </strong>
			</Typography>

			<Typography variant="body1" gutterBottom align="justify">
				<table width='100%' class={classes.tabla}>
					<tbody style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
						<tr>
							<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
								<p><span >25/07/2021</span></p>
							</td>
							<td style={{ "borderWidth": "2px", "borderColor": "#000", "borderStyle": "solid" }}>
								<p><span >Versi&oacute;n 1.1</span></p>
							</td>
						</tr>
					</tbody>
				</table>
			</Typography>
			<br /><br />
		</Container>
	)
}

export default Reglas