import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { IconButton, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import LogoTheNest from './../assets/images/TheNest.svg';
import LogoPanini from './../assets/images/PaniniLogo.svg';
import NavLink from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import {cerrarSesionAccion} from '../Redux/usuarioDucks';
import {useDispatch,useSelector} from 'react-redux';


const useStyles = makeStyles(theme => ({
    root: {

    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }

    },//espacio para el titulo envie todo a la derecha hasta donde alcancesn los elementos
    //https://codesandbox.io/s/material-demo-xu80m?file=/index.js
    title: {
        flexGrow: 1
    },
    menubuttons: {
        marginRight: theme.spacing(2),
    }
    ,
    appBar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: 240,
        }
    }, logotheNest: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        marginRight: theme.spacing(2),
        height: 50,
        width: 'auto',
    }
    , logoPanini: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        marginRight: theme.spacing(1),
        height: 40,
        width: 'auto',

    }

}))

const Navbar = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const jugador = useSelector(store => store.usuario)
    //console.log("demo de selector" + JSON.stringify(jugador) )
    //const 
    
    const cerrarSesion = () => {
        dispatch(cerrarSesionAccion()).then(
         //props.history.push('login')
        );
       //
     }

    return (
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="menu"
                    className={classes.menuButton}
                    onClick={() => props.abrirMenuf()}
                >
                    <MenuIcon />
                </IconButton>
                <Hidden smDown>
                    <NavLink component={RouterLink} to="/" exact className={classes.title}>
                        <Button variant="text" className={classes.menubuttons} color="inherit" >
                            <img src={LogoTheNest} alt="THE NEST" className={classes.logotheNest}></img>
                            <img src={LogoPanini} alt="THE NEST" className={classes.logoPanini}></img>
                        </Button>
                    </NavLink>
                    {jugador.activo === true ?
                    <NavLink component={RouterLink} color="secondary" to="/Jugador" >
                        <Button color="inherit" className={classes.menubuttons}>
                            <Typography variant="h6" style={{ color: 'white', marginRight: '20px' }}>
                                JUGADOR
                            </Typography>
                        </Button>
                    </NavLink>:<></>}
                    <NavLink component={RouterLink} color="secondary" to="/#seccionRegistro" >
                        <Button color="inherit" className={classes.menubuttons}>
                            <Typography variant="h6" style={{ color: 'white', marginRight: '10px' }}>
                                REGISTRO
                            </Typography>
                        </Button>
                    </NavLink>
                    <NavLink component={RouterLink} color="secondary" to="/reglas" >
                        <Button color="inherit" className={classes.menubuttons}>
                            <Typography variant="h6" style={{ color: 'white' }}>
                                REGLAS
                            </Typography>
                        </Button>
                    </NavLink>
                    <NavLink component={RouterLink} color="secondary" to="/#seccionPreguntas" >
                        <Button variant="text" color="inherit" className={classes.menubuttons} >
                            <Typography variant="h6" style={{ color: 'white' }}>
                                FAQ
                            </Typography>
                        </Button>
                    </NavLink>
                    {jugador.activo === true ?
                        <Button variant="text" color="inherit" style={{ marginLeft: '40px' }} 
                            onClick={()=> cerrarSesion()}>
                            <Typography variant="h6" style={{ color: 'white' }}>
                                SALIR
                            </Typography>
                        </Button> :  <NavLink component={RouterLink} color="secondary" to="/login" >
                        <Button variant="text" color="inherit" style={{ marginLeft: '40px' }} >
                            <Typography variant="h6" style={{ color: 'white' }}>
                                INGRESAR
                            </Typography>
                        </Button>
                    </NavLink>  
                        }
                </Hidden>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar
