import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { registrarDeckStandard,registrarDeckTematico } from '../Redux/playerTournamentData';
import Alert from '@material-ui/lab/Alert';
import Progress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import DeckLists from '../dataLists/deckLists';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { storage } from '../firebase';
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    boton: {
       marginTop:theme.spacing(0)
    },
    InputDeckBox: {
       border: 1,
       borderColor: theme.palette.primary.main,
       display: 'flex',
       flexWrap: 'wrap',
       marginTop: theme.spacing(4),
 
       paddingRight: theme.spacing(2),
       paddingBottom: theme.spacing(4),
       [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(0),
          paddingRight: theme.spacing(0),
 
       }
 
    }, CajaDeckRegistrado: {
       border: 6,
       borderColor: theme.palette.secondary.main,
       display: 'flex',
       flexWrap: 'wrap',
       paddingTop: theme.spacing(3),
       [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(0),
       },
 
 
    }, instrucciones: {
       paddingBottom: theme.spacing(2),
    },
    loadingBar: {
       paddingTop: theme.spacing(1),
       paddingBottom: theme.spacing(1)
 
    }, imageDeckList: {
       alignItems: "center",
       marginTop: theme.spacing(1),
       marginBottom: theme.spacing(1),
       marginRight: 'auto',
       marginLeft: 'auto',
       display: 'block',
       height: 250,
       width: 'auto'
    }, form: {
       width: '100%', // Fix IE 11 issue.
       display: "flex"
    },
    containerItemImage: {
       [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(3),
       },
    },
    TextFieldsTematico:{
       paddingBottom: theme.spacing(3),
       paddingRight: theme.spacing(5),
       paddingLeft: theme.spacing(5),
       [theme.breakpoints.down('sm')]: {
          paddingBottom: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
       },
    }
 }));
const initialFormValues = {
    ptcgoName: '',
    deckList: '',
 }

 



const RegistroDeckTematico = () => {

    const dataPlayer = useSelector(store => store.playerTournamentData.dataPlayer);
 const dispatch = useDispatch();
 const classes = useStyles();
   /***               ***/
   /*** DECK TEMATICO ***/
   /***               ***/

   //Variables del formulario de Torneo Tematico
   const [formValues, setFormValues] = useState(initialFormValues);
   const [deckTReg, setDeckTReg] = useState("dT");
   const [formErrors, setFormErrors] = useState({});
   const deckLists = DeckLists;

   const [urlImage, setUrlImage] = useState("nu")

    //VAriables del componente de Redux Tematico
    //const dataPlayer = useSelector(store => store.playerTournamentData.dataPlayer);
    const loadingDeckListTematico = useSelector(store => store.playerTournamentData.loadingENVIO_DECK_TEMATICO);
    const errorDeckListTematico = useSelector(store => store.playerTournamentData.errorENVIO_DECK_TEMATICO);
    const successDeckListTematico = useSelector(store => store.playerTournamentData.successENVIO_DECK_TEMATICO);
    //const dispatch = useDispatch();

   //Leer variable cuando se actualiza deck LOCAL
   useEffect(() => {

      console.log("Effecto del valor tematico")
      console.log("dataPlayer: " + JSON.stringify(dataPlayer))
      console.log("dataPlayer.dataDeckList: " + dataPlayer.dataDeckList)
      if ( formValues.deckList) {
         // setDeckTReg(dataPlayer.dataDeckList);
         let x = _.find(deckLists, ["value", formValues.deckList]).urlImage;
         console.log(" valor a dibujar seleccionado " + x)
         const storageRef = storage.refFromURL(x)
         storageRef.getDownloadURL().then((url) => {
            console.log(url);
            setUrlImage(url);
         });
      }


   }, [formValues.deckList])


   //Leer variable cuando se actualiza deck Obteniendo fetching
   useEffect(() => {

      console.log("Effecto dataDeckList tematico")
      console.log("dataPlayer: " + JSON.stringify(dataPlayer))
      console.log("dataPlayer.dataDeckList: " + dataPlayer.dataDeckList)
     if (deckTReg === "dT" && dataPlayer.dataDeckList) {
         setDeckTReg(dataPlayer.dataDeckList);


      console.log("valiendo dentro del efecto del deck list " + JSON.stringify(dataPlayer.dataDeckList) )


         let x = _.find(deckLists, ["value", dataPlayer.dataDeckList.deckList]).urlImage;
         console.log(" valor a dibujar seleccionado " + x)
         const storageRef = storage.refFromURL(x)
         storageRef.getDownloadURL().then((url) => {
            console.log(url);
            setUrlImage(url);
         });
      }


   }, [dataPlayer.dataDeckList])


   //MEtodos de form Tematico
   const handleInputChange = e => {
      console.log("cambiamos valor en el tematico")
      const { name, value } = e.target
      setFormValues({
         ...formValues,
         [name]: value
      })
   }

   function validateForm() {

      let tempError = {}

      tempError.ptcgoName = formValues.ptcgoName ? "" : "Ingresa tu nombre del juego PTCGO"
      tempError.deckList = formValues.deckList ? "" : "Elige un deck tematico."

      setFormErrors({
         ...tempError
      })

      //hace una comparativa en el objeto temporal para validar las validaciones del formulario
      return Object.values(tempError).every(x => x === "")
   }

   const handleSubmitTematico = e => {
      console.log("vamos a subir tematico")
      e.preventDefault();

      if (validateForm()) {
         //setFormValues(initialFormValues);
         console.log("formulario valido con estos valores")
         console.log("formValues" + JSON.stringify(formValues))
         dispatch(registrarDeckTematico(formValues))
         setFormValues(initialFormValues);
         return
      }
      console.log("formulario invalido")
      console.log("formValues" + JSON.stringify(formValues))
   }



    return (
        <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.contenedor}>
            <Grid item xs={12}  >
               <Typography variant="h5" align="center" gutterBottom>
                  <strong>Registro de deck Tematico </strong>
               </Typography>
               <Typography variant="body2" align="center" className={classes.instrucciones} >
                  <p> Selecciona un deck tematico y registra tu nickname tal y como aparece en el juego de PTCGO.</p>
               </Typography>
            </Grid>
            
            <form  className={classes.form} noValidate autoComplete="false" onSubmit={handleSubmitTematico}>
               <Grid container justifyContent="center" alignItems="flex-start" direction="row">
                  <Grid container justifyContent="center" alignItems="center" item xs={12} sm={6} >
                     <Grid item xs={12} sm={12} className={classes.TextFieldsTematico}>
                        <Typography variant="h6" align="left" gutterBottom>
                           Ingresa tu Nickname del juego PTCGO.
                        </Typography>
                        <TextField
                           name="ptcgoName"
                           variant="outlined"
                           required
                           fullWidth
                           id="PTCGO Name"
                           label="PTCGO Name"
                           size="small"
                           error={formErrors.ptcgoName}
                           helperText={formErrors.ptcgoName}
                           value={formValues.ptcgoName}
                           onChange={handleInputChange}
                        />
                     </Grid>
                     <Grid item xs={12} sm={12} className={classes.TextFieldsTematico}>
                        <Typography variant="h6" align="left" gutterBottom>
                           Selecciona un Deck Tematico.
                        </Typography>
                        <TextField
                           variant="outlined"
                           required
                           select
                           id="deckList"
                           label="Theme Deck"
                           name="deckList"
                           size="small"
                           fullWidth
                           error={formErrors.deckList}
                           helperText={formErrors.deckList}
                           // {...(formErrors.deckList && { error:true, helperText : formErrors.deckList })}
                           value={formValues.deckList}
                           onChange={handleInputChange}>
                           {
                              deckLists.map((option) => (
                                 option.urlImage === undefined ? <MenuItem disabled className={classes.subtitulo}><Box className={classes.subtitulo}>{option.label}</Box></MenuItem> :
                                    <MenuItem key={option.value} value={option.value}>
                                       {option.label}
                                    </MenuItem>
                              ))
                           }
                        </TextField>
                     </Grid>
                     <Grid item xs={12} className={classes.loadingBar}>
                  {errorDeckListTematico && (<Box mt="20px">
                     <Alert severity="error">
                        {errorDeckListTematico}
                     </Alert></Box>)}
                  {loadingDeckListTematico && (<Progress className={classes.loadingBar} />)}
                  {successDeckListTematico && (<Box mt="20px" textAlign="center">
                     <Typography variant="subtitle1" align="center" color="success" />
                     <Alert severity="success">
                        {successDeckListTematico}
                     </Alert></Box>)}
               </Grid>
               <Typography paragraph variant="h6" color="primary" align="center">Registro Cerrado</Typography>
                   {/*  <Grid container item justify="center">
                        <Button type="submit" variant="contained" color="primary" size="large"  className={classes.boton}>
                           Registrar
                        </Button>
                     </Grid>*/ }
                  </Grid>

                  <Grid  container item  xs={12} sm={6}  className={classes.containerItemImage}>
                     <Grid container item justify="left" >
                        <Typography gutterBottom variant="h6" align="left"  >
                           Deck Registrado
                        </Typography>
                     </Grid>
                     {deckTReg === "dT" && urlImage === "nu" ?
                        <Typography gutterBottom variant="body1" align="left" color={"error"}>
                           Aún no ha sido registrado un deck Tematico
                        </Typography>
                        
                        : <>
                           <Grid container item justify="center">
                              <img alt="deckImage" className={classes.imageDeckList} src={urlImage} />
                           </Grid>
                        </>
                     }
                  </Grid>
               </Grid>

            </form>
                 






         </Grid>
    )
}

export default RegistroDeckTematico
