import React from 'react'
import {makeStyles,Hidden} from '@material-ui/core'
import Navbar from "../componentes/Navbar";
import Cajon from './Cajon';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Login from '../Pages/Login';
import ScrollPage from '../Pages/ScrollPage';
import RegistroTheme from '../Pages/RegistroTheme';
import TerminosyCondiciones from '../Pages/TerminosyCondiciones';
import Reglas from '../Pages/Reglas';
import AvisoDePrivacidad from '../Pages/AvisoDePrivacidad';
import RegistroStandard from '../Pages/RegistroStandard';
import Jugador from '../Pages/Jugador';


const estilos = makeStyles (theme =>({
    root:{
        display:'flex',
        paddingRight:'0px'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow:1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(0),
       
    }
}))

const MainContenedor = () => {

    const classes = estilos();
    const [abrir, setAbrir] = React.useState(false)

    const abrirMenu = () =>{
        setAbrir(!abrir)
    }

    return (
        <div className ={classes.root}>
            <Navbar abrirMenuf = {abrirMenu}/>
           
            <Hidden smUp>
                <Cajon
                    variant = "temporary"
                    open={abrir}
                    onClose= {abrirMenu}
                />
            </Hidden>
            <div className={classes.content}>
                <div className={classes.toolbar}></div>
                <Route path ="/" exact>
                    <ScrollPage/>
                </Route>
                <Route path ="/login">
                    <Login/>
                </Route>
                <Route path ="/RegistroTheme">
                    <RegistroTheme/>
                </Route>
                <Route path ="/RegistroStandard">
                    <RegistroStandard/>
                </Route>
                <Route path ="/Jugador">
                    <Jugador/>
                </Route>
                <Route path ="/terminosYCondiciones">
                    <TerminosyCondiciones/>
                </Route>
                <Route path ="/avisoDePrivacidad">
                    <AvisoDePrivacidad/>
                </Route>
                <Route path ="/reglas">
                    <Reglas/>
                </Route>
            </div>
        </div>
    )
}

export default MainContenedor
