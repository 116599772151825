import React , {useRef} from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import ThemeLogo from './../assets/images/ThemeLogo.svg';
import StandardLogo from './../assets/images/StandardLogo.svg';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import MainBackground from './../assets/images/Banner.png';
import imgTorneo1 from './../assets/images/torneo1.png';
import imgTorneo2 from './../assets/images/torneo2.png';
import imgTorneo3 from './../assets/images/torneo3.png';
import imgTorneo4 from './../assets/images/torneo4.png';
import imgTorneo5 from './../assets/images/torneo5.png';
import imgTorneo6 from './../assets/images/torneo6.png';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography';
import ScrollIndicator from '../componentes/ScrollIndicator';
import { useLocation } from "react-router-dom";
import PreguntasFrecuentes from '../componentes/PreguntasFrecuentes';



//Jalamos estilos del tema y customizamos
const useStyles = makeStyles(theme => ({

    paper: {
        //background: '#9319FF',
        textAlign: 'center',
    }, paperRegistros: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingRight: theme.spacing(0),
            paddingLeft: theme.spacing(0),
            //backgroundColor:'#002632'
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            //backgroundColor:'#124452'
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(7),
            paddingBottom: theme.spacing(8),
            paddingRight: theme.spacing(8),
            paddingLeft: theme.spacing(8),
            //backgroundColor:'#221642'
        }
    },
    botonPortada: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    botonRegistro: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    logoRegistro: {
        display: 'block',
        width: '50%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(0)
    },
    seccionPortada: {
        backgroundImage: `url(${MainBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: 'auto',
        height: '100vh',
        display: 'flex',
    }, elementsPortada: {
        paddingBottom: theme.spacing(16),
    },
    seccionTorneo: {
        background: '#FFFFFF',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),

    }, seccionRegistro: {
        backgroundColor: '#F2F2F2',
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(6)

    }, seccionPreguntas: {
        backgroundColor: '#FFFFFF',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    }, seccionFooter: {
        backgroundColor: '#E00C19',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        color: '#FFFFFF'
    },
    /** imagenes **/
    imgTorneo: {
        width: '100%',
        padding: theme.spacing(4)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))



const ScrollPage = () => {

    const classes = useStyles();
    //const { pathname } = useLocation();
    //const commentSection = useRef(pathname);
    const commentSection = useRef();

    const gotoCommentSection = ()=>{
            window.scrollTo({
                top:commentSection.current.offsetTop,
                behavior:"smooth"
            });    
    }

    return (<>
    <ScrollIndicator/>
        <Container id="seccionPortada" maxWidth={false} disableGutters className={classes.seccionPortada} >
            <Grid container direction="column" justifyContent='flex-end' alignItems="center" className={classes.elementsPortada}>
                <Grid item >
                    <Typography variant="h4" align='center' style={{ color: 'white' }}>Conviértete en el mejor entrenador</Typography>
                </Grid>
                <Grid item className={classes.botonPortada}>
                    <Button variant="contained" size="large" color="secondary" align='center'
                        alignSelf="center" onClick={gotoCommentSection}>
                        <Typography variant="h6" align='center' >
                            ¡QUIERO JUGAR!
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Container>

        <Container  id="seccionTorneo" maxWidth="lg" maxWidth={false} className={classes.seccionTorneo}>
            <Grid container spacing={3} justifyContent='center' alignItems="center">
                <Grid item xs={12}  >
                    <Typography variant="h4" gutterBottom align='center'>TORNEO<br /></Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.paper} >
                    <Paper elevation={12} >
                        <img src={imgTorneo1} className={classes.imgTorneo} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.paper}>
                    <Paper elevation={12}>
                        <img src={imgTorneo2} className={classes.imgTorneo} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.paper}>
                    <Paper elevation={12}>
                        <img src={imgTorneo3} className={classes.imgTorneo} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.paper}>
                    <Paper elevation={12}>
                        <img src={imgTorneo4} className={classes.imgTorneo} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.paper}>
                    <Paper elevation={12}>
                        <img src={imgTorneo5} className={classes.imgTorneo} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.paper}>
                    <Paper elevation={12}>
                        <img src={imgTorneo6} className={classes.imgTorneo} />
                    </Paper>
                </Grid>
            </Grid>
        </Container >
      
        <Container ref={commentSection} id="#seccionRegistro" maxWidth={false} className={classes.seccionRegistro}>
            <Grid container spacing={0} align='center'>
                <Grid item xs={12}  >
                    <Typography variant="h4" align='center'>ELIGE UN TORNEO<br /></Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.paperRegistros}>
                    <Paper elevation={6}>
                        <img src={StandardLogo} className={classes.logoRegistro} />
                        <Link component={RouterLink} to="/RegistroStandard" >
                            <Button variant="contained" color="primary"
                                size="large" className={classes.botonRegistro}>
                                <Typography variant="button" style={{ color: "#FFFFFF" }}>
                                    Registrate
                                </Typography>
                            </Button>
                        </Link>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.paperRegistros}>
                    <Paper elevation={6}>
                        <img src={ThemeLogo} className={classes.logoRegistro} />
                        <Link component={RouterLink} to="/RegistroTheme" >
                            <Button variant="contained" color="primary"
                                size="large" className={classes.botonRegistro}>
                                <Typography variant="button" style={{ color: "#FFFFFF" }}>
                                    Registrate
                                </Typography>
                            </Button>
                        </Link>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        {/*** SECCION PREGUNTAS Y RESPUESTAS ***/} 
        <Container id="#seccionPreguntas" maxWidth={false} className={classes.seccionPreguntas}>
            <PreguntasFrecuentes/>
        </Container>
        {/*** SECCION FOOTER ***/} 
        <Container id="#footer" maxWidth={false} className={classes.seccionFooter}>
            <Grid container spacing={3} justifyContent='center' alignItems="center" >
                <Grid item >
                    <Box mr={1}>
                        <Link component={RouterLink} to="/terminosYCondiciones" >
                            <Typography variant="button" style={{ color: "#FFFFFF" }} >
                                Terminos y condiciones
                            </Typography>
                        </Link>
                    </Box>
                </Grid>
                <Grid item>
                    <Box ml={1.5}>
                        <Link component={RouterLink} to="/avisoDePrivacidad" >
                            <Typography variant="button" style={{ color: "#FFFFFF" }}>
                                Aviso de Privacidad
                            </Typography>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </>
    )
}

export default ScrollPage
