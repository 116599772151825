import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LogoTheNest from '../assets/images/PaniniTorneo.png';
import LinkMaterialUI from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Link} from  'react-router-dom';
import {ingresoUsuarioAccion} from '../Redux/usuarioDucks';
import {useDispatch,useSelector} from 'react-redux';
import Progress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import {withRouter} from 'react-router-dom';

 const useStyles = makeStyles((theme) => ({
        paper: {
          marginTop: theme.spacing(4),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        avatar: {
          margin: theme.spacing(1),
          backgroundColor: theme.palette.secondary.main,
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        },
        submit: {
          margin: theme.spacing(3, 0, 2),
        },logotheNest: {
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(3),
          height:400,
          width:'auto'
        }
      }));


      function Copyright() {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <LinkMaterialUI color="inherit" href="https://sangsang.mx">
              Sangsang Technologies
            </LinkMaterialUI>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }

      
const initFormLoginValues = {
    email:'',
    password:'',
  }

  
function Login(props) {
  
    const classes = useStyles();
  
    //Variables del formulario
    const [formLoginValues, setFormLoginValues] = useState(initFormLoginValues);
    const [formLoginErrors,setFormLoginErrors] = useState({});
  
    
    const dispatch = useDispatch();
    const loadingLogin = useSelector(store => store.usuario.loading);
    const errorLogin = useSelector(store => store.usuario.error);
    const jugador = useSelector(store=> store.usuario);
    
    const handleInputChange = e =>{
      const {name, value} = e.target
      setFormLoginValues({
        ...formLoginValues,
        [name] : value
      })
    }

  

function validateForm  (){
    
    let tempError = {}
      tempError.email = (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(formLoginValues.email) ? "":"Email is not valid." 
      tempError.password = formLoginValues.password ? "":"This field is required."  
     // console.log(tempError)
      
      setFormLoginErrors({
        ...tempError
      })
    //hace una comparativa en el objeto temporal para validar las validaciones del formulario
    return Object.values(tempError).every( x=> x==="")
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  const handleSubmit =(e) =>{
      e.preventDefault();
      //Si es valido el form llamamos a Redux para la sig capa 
      if (validateForm()){
        //console.log("formValues OK dispatching accion" + JSON.stringify(formLoginValues))
         dispatch(ingresoUsuarioAccion(formLoginValues));
        //setFormLoginValues(initFormLoginValues);
        return
      }
      //console.log("formValues Invalid" + JSON.stringify(formLoginValues))
  }

  useEffect(() => {
    console.log("EFFECT/LOGIN PAGE - Por decidir" + jugador);
    if(jugador.activo === true){
      console.log("EFFECT/LOGIN PAGE - User activo es true: " + jugador);
      props.history.push("jugador")
    }else{
     // console.log("EFFECT/LOGIN PAGE - no empuje el usuario a admin porque no hay user activo" + jugador);
     // props.history.push("login")
     // props.history.push("")
    }
   
  }, [jugador])


    return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={LogoTheNest} className={classes.logotheNest} alt="logoTheNest"/> 
          <Typography variant="body2" align="center" gutterBottom>
            ¡Registrate,compite y gana!
	        </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              {...(formLoginErrors.email && { error:true, helperText : formLoginErrors.email })}
              onChange ={handleInputChange}
              value = {formLoginValues.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={formLoginErrors.password}
              helperText = {formLoginErrors.password}
              {...(formLoginErrors.password && { error:true, helperText : formLoginErrors.password })}
              onChange={handleInputChange}
              value = {formLoginValues.password}
            />{/*
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />*/}
           {  errorLogin && ( <Box  mt="20px"><Alert severity="error">{errorLogin}</Alert></Box> )} 
           { loadingLogin && ( <Progress/>)} 
      
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={e => handleSubmit}
              disabled= {loadingLogin}
            >
              Ingresar
            </Button>
            
            <Grid container>
              {/*<Grid item xs>
                <Link to="registro" variant="body2">
                  Forgot password?
                </Link>
              </Grid>*/}
              <Grid item>
                <Link to="/#seccionRegistro" variant="body2">
                  {"¿No tienes cuenta?, registrate aquí"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
}

export default withRouter(Login)
