import {auth, db,firebaseApp} from './../firebase'

//data Inicial
const dataInicial = {
    loading: false,
    error:"",
    success:""
}

//types
const LOADING_REGISTRO = "LOADING_REGISTRO"
const REGISTRO_USUARIO_ERROR = 'REGISTRO_USUARIO_ERROR'
const REGISTRO_USUARIO_EXITO = "REGISTRO_USUARIO_EXITO"
const RESET_FORM = "RESET_FORM"

//reducers 
export default function registroUserReducer (state = dataInicial,action){
    switch(action.type){

        case LOADING_REGISTRO:
            return {...state, loading:true}
        case REGISTRO_USUARIO_EXITO:
            return {...state, loading:false, success:"Register success", error:""}
        case REGISTRO_USUARIO_ERROR:
            return { ...dataInicial, error:action.payload}
        case RESET_FORM:
            return { ...dataInicial, success:""}
        default:
            return{...state}
    }
}

//Actions
//funcion asincrona que puede pasarse de argunemto el dispatch y el getstate (opcional)
export const registrarUsuarioAccion = (formData,torneo) => async (dispatch) =>{

    //console.log("en Ducks " + formData)
    //console.log("some data" + formData.password + formData.email)
    dispatch({
        type: LOADING_REGISTRO
    })
    try{
        const res = await auth.createUserWithEmailAndPassword(formData.email,formData.password);
        //console.log(res.user);
        await db.collection('usuarios').doc(res.user.email).set({
            email:res.user.email,
            uid:res.user.uid,
            firstName: formData.firstName,
            lastName: formData.lastName,
            estado:formData.estado,
            fechaNacimiento:formData.fecha,
            tutor : formData.tutor,
            telefonoTutor: formData.telefonoTutor,
            torneo: torneo,
            timeRegistered: firebaseApp.firestore.FieldValue.serverTimestamp()
        });
        await db.collection(torneo).doc(res.user.email).set({
            uid:res.user.uid,
            userData:{
                firstName: formData.firstName,
                lastName: formData.lastName,
                estado:formData.estado,
                fechaNacimiento:formData.fecha,
                tutor : formData.tutor,
                telefonoTutor: formData.telefonoTutor,
                torneo: torneo
            },
            paso:0,
            timeRegistered: firebaseApp.firestore.FieldValue.serverTimestamp()
        })
       
       
        dispatch({
            type:REGISTRO_USUARIO_EXITO
        })

    }catch(error){
        //console.log(error);
        let errorX = ""

        if(error.code === 'auth/invalid-email'){
            errorX = "Email invalido"
        }else if (error.code === 'auth/email-already-in-use'){
           errorX = "Correo ya registrado." 
        }else{
            errorX = error.message
        }
        dispatch({
            type: REGISTRO_USUARIO_ERROR,
            payload: errorX
        })

    }

}

export const resetForm = ()=>async (dispatch)=>{
    dispatch({
        type: RESET_FORM
    })
}
